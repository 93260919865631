import React, { useState, useEffect } from "react";
import { useToggle } from "../provider/context";

import { IoCloseSharp } from "react-icons/io5";
export default function SidenavHeader() {
  const { toggle, ref } = useToggle();
  const useMediaQuery = () => {
    const [matches, setMatches] = useState(
      window.matchMedia("(max-width: 768px)").matches
    );

    useEffect(() => {
      const handler = (event) => setMatches(event.matches);
      const media = window.matchMedia("(max-width: 1024px)");
      media.addEventListener("change", handler);
      setMatches(media.matches);
      return () => media.removeEventListener("change", handler);
    }, []);

    return matches;
  };
  return (
    <div
      className={`bg-[#000F3C] sticky top-0
 z-10  flex h-24 items-center justify-center pl-10`}
    >
      <div className="flex w-full justify-between ">
        <img
          src="/images/AGB_Logo.svg"
          height={100}
          width={100}
          alt="Free Admin logo"
        />
        {useMediaQuery() && (
          <button onClick={toggle} className="pr-5 text-white" ref={ref}>
            <IoCloseSharp className="text-[25px] text-white" />
          </button>
        )}
      </div>
    </div>
  );
}
