import { Link } from "react-router-dom";
import data from "./data";
export default function SidenavItems() {
  const style = {
    inactive: "text-[#dfdfdf8a]",
    active: "font-medium text-[#fff]",
    link: "flex items-center justify-start my-2 p-4 text-sm w-full hover:text-[#fff]",
  };
  return (
    <>
      <ul className="tablet:pl-6">
        <li>
          {data.map((item) => (
            <Link to={item.link} key={item.title}>
              <span
                className={`${style.link} 
       ${style.inactive}`}
              >
                <span>{item.icon}</span>
                <span className="mx-4">{item.title}</span>
              </span>
            </Link>
          ))}
        </li>
      </ul>
    </>
  );
}
