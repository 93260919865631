import React, { useEffect, useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";
import CustomizedPublishCell from '../../components/CustomizedCell/CustomizedPublishCell'
import ActionComponent from "../../components/ActionComponent/index";
import CustomAlert from "../../../src/components/Notification/CustomAlert";
import LoadingComponent from "../../../src/components/Loading";
import CustomizedTable from "../../components/CustomizedTable/index";
import "react-toastify/dist/ReactToastify.css";
import ActivitiesCreateForm from "../../components/ModalBox/ActivitiesCreateForm";
import ActivitiesUpdateForm from "../../components/ModalBox/ActivitiesUpdateForm";
import CustomizedImageCell from '../../components/CustomizedCell/CustomizedImageCell'
import clearSearch from "../../components/CustomizedTable/util";
import Header from "../../components/PageHeader/index";
import FormModal from "../../components/FormModal";

const ActivitiesPage = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isShowCreate, setIsShowCreate] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [filterData, setFilterData] = useState("");
  const [searchText, setSearchText] = useState("");
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    type: "",
    btnLabel: "",
    btnColor: "",
    onBtnClick: null,
  });

  const defaultData = {
    _id: "",
    title: "",

    content: "",

    date: "",
    time: "",
    publish: false,
    imageStore: [],
    images: [],
  };

  const [formData, setFormData] = useState(defaultData);
  const [d, setD] = useState([]);

  const activities = {
    columns: [
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Time",
        accessor: "time",
      },
      {
        Header: "Image",
        accessor: "image",
      },
      {
        Header: "Publish",
        accessor: "publish",
        filter: true,
        type: "multiselect",
        options: [
          {
            value: true,
            label: "Active",
          },
          {
            value: false,
            label: "Unpublished",
          },
        ],
      },
    ],
    datas: [
      {
        id: "66851c9cff01702bcfe0c75c",
        title: "<p><strong>AGB’S DONATION</strong></p>",
        description: "<p>AGB’S DONATION</p>",
        date: "Tue Oct 22 2019",
        time: "10:00 am",
        publish: true,
        image: [
          "http://172.18.29.250:8000/bucket/nova-bucket/activities/77b6702b-6805-42e3-9f68-3d46b85e64a9-main.jpg-.jpg",
        ],
        imageStore: [
          "/activities/77b6702b-6805-42e3-9f68-3d46b85e64a9-main.jpg-.jpg",
        ],
      },
      {
        id: "66851c40ff01702bcfe0c741",
        title:
          "<p><strong>AGB'S THINGYAN AND APRIL BIRTHDAY CELEBRATION 2024</strong></p>",
        description:
          "<p>AGB'S THINGYAN AND APRIL BIRTHDAY CELEBRATION 2024</p>",
        date: "Thu Apr 11 2024",
        time: "2:00 pm",
        publish: true,
        image: [
          "http://172.18.29.250:8000/bucket/nova-bucket/activities/3f0e764c-2f1b-40ea-a8cb-238e0eb7ba88-44.jpg-.jpg",
        ],
        imageStore: [
          "/activities/3f0e764c-2f1b-40ea-a8cb-238e0eb7ba88-44.jpg-.jpg",
        ],
      },
    ],
    count: 7,
  };

  const titles = activities
    ? activities.columns.filter((x) => x.filter).map((col) => col.accessor)
    : [];
  const [multiSelectValue, setMultiSelectValue] = useState(
    titles.reduce((accumulator, value) => ({ ...accumulator, [value]: [] }), {})
  );
  const defaultFilteredTargetValues = titles.reduce(
    (accumulator, value) => ({ ...accumulator, [value]: ["DEFAULT"] }),
    {}
  );
  const [filteredTargets, setFilteredTargets] = useState(
    defaultFilteredTargetValues
  );

  const handleFilter = () => {
    // let result = "";
    // Object.entries(filteredTargets).forEach(([key, value]) => {
    //   result += value[0] === "DEFAULT" ? "" : `&${key}=${value.join(",")}`;
    // });
    // setFilterData(result);
    console.log('hi')
  };

  
  const exportExternal = async (format) => {
    console.log("hi");
  };

  const editData = async (row) => {
    const tableData = {
      _id: row.id,
      title: row.title,

      description: row.description,

      date: row.date,
      time: row.time,
      publish: row.publish,
      image: row.image,
      imageStore: row.imageStore,
    };
    setFormData(tableData);
    setIsOpenEdit(true);
  };

  const deleteData = async (row) => {
    setAlertState({
      ...alertState,
      open: false,
    });
    await trigger({ ...activities, page, limit, filterData });
    await mutate();
  };

  const columns = useMemo(() => {
    if (!activities) return [];
    const columnData = [
      {
        Header: "Action",
        accessor: "action", // accessor is the "key" in the data
        filter: false,
        Cell: ({ row }) =>
          ActionComponent({
            row,
            alertState,
            setAlertState,
            editData,
            deleteData,
          }),
        width: 100,
      },
      ...activities.columns,
    ];
    const changeCol = columnData.map((col) => {
      const column = col;

      if (column.accessor === "publish") {
        column.Cell = ({ cell }) => CustomizedPublishCell({ cell });

        column.width = 50;
      }

      if (column.accessor?.includes("image")) {
        column.Cell = ({ cell }) =>
          cell?.value ? CustomizedImageCell({ cell }) : "";

        column.width = 200;
      }

      return column;
    });
    return changeCol;
  }, [activities]);

  const globalColumn = useMemo(() => {
    const global = columns.map((col) => {
      const tempColumn = col;
      tempColumn.show = true;
      return tempColumn;
    });
    return global;
  }, [columns]);

 
  return (
    <>
      <div className="absolute right-0 top-0 m-5 bg-white">
        <ToastContainer autoClose={3000} />
      </div>

      <Header pageName="Activities" />

      <CustomizedTable
        dataRows={activities?.datas}
        dataColumns={columns}
        totalItems={activities.count}
        pagination
        page={page}
        setPage={(e) => setPage(e)}
        limit={limit}
        setLimit={(e) => setLimit(e)}
        handleFilter={handleFilter}
        clearSearch={() =>
          clearSearch(
            setFilteredTargets,
            defaultFilteredTargetValues,
            setFilterData,
            multiSelectValue,
            setMultiSelectValue
          )
        }
        multiSelectValue={multiSelectValue}
        setMultiSelectValue={setMultiSelectValue}
        filteredTargets={filteredTargets}
        setFilteredTargets={setFilteredTargets}
        defaultFilteredTargetValues={defaultFilteredTargetValues}
        loading={false}
        onCreate={() => setIsShowCreate((value) => !value)}
        progressComponent={<LoadingComponent themeColor="#000F3C" />}
        refreshRow={() => trigger({ ...activities, page, limit, filterData })}
        searchText={searchText}
        setSearchText={setSearchText}
        exportExternal={exportExternal}
        colWithShow={globalColumn}
        pageName="activities"
      />
      <FormModal
        header="Create Activities"
        isOpen={isShowCreate}
        title="Create Event"
      >
        {/* <ActivitiesCreateForm
          type="createForm"
          previewId="previewDialog"
          onCancel={() => setIsShowCreate(false)}
          onSubmitEvent={(e) => submitEvent(e, "create")}
        /> */}
        <ActivitiesCreateForm />
      </FormModal>
      <FormModal
        isOpen={isOpenEdit}
        onClose={() => {
          setIsOpenEdit(false);
        }}
        header="Edit Activities"
      >
        <ActivitiesUpdateForm />
      </FormModal>
      <CustomAlert
        open={alertState.open}
        type={alertState.type}
        message={alertState.message}
        btnColor={alertState.btnColor}
        btnLabel={alertState.btnLabel}
        onClose={() => setAlertState({ ...alertState, open: false })}
        onBtnClick={alertState.onBtnClick}
      />
    </>
  );
};

export default ActivitiesPage;
