import React from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import styles from "../style.module.css";

function BottomLoading({ enter, bg }) {
  return (
    <CSSTransition
      classNames={{
        enterActive: styles.botWallEnterActive,
        enterDone: styles.botWallEnterDone,
        exitActive: styles.botWallExitActive,
        exitDone: styles.botWallExitDone,
      }}
      in={enter}
      mountOnEnter
      unmountOnExit
      timeout={200}
    >
      <div
        style={{ backgroundColor: bg }}
        className="fixed w-full h-[50%] bottom-0 left-0 z-50"
      />
    </CSSTransition>
  );
}

export default BottomLoading;

BottomLoading.propTypes = {
  bg: PropTypes.string.isRequired,
  enter: PropTypes.bool.isRequired,
};
