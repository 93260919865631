import { BiExpand, BiReset } from "react-icons/bi";
import { GiSave } from "react-icons/gi";
import { BsPlusCircle } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import React, { useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import PropsTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import BannerUpdateForm from "../Forms/BannerUpdateForm";
import BannerCreateForm from "../Forms/BannerCreateForm";
import FormModal from "../FormModal";
import styles from "./style.module.css";
import PrimaryButton from "../Buttons/PrimaryButton";
import CustomAlert from "../Notification/CustomAlert";
import axiosClient from "../../axios-client";

function BannerComponent({ bannerData, refresh }) {
  const [isShowCreate, setIsShowCreate] = useState(false);
  const [updateDetail, setUpdateDetail] = useState({
    show: false,
    detail: null,
  });
  const [showDelAlert, setShowDelAlert] = useState({ id: "", show: false });
  const [data, setData] = useState(bannerData);

  useEffect(() => {
    setData(bannerData);
  }, [bannerData]);

  const createBanner = async (body) => {
    try {
      const response = await axiosClient.post(`/backpanel/banner`, body);
      toast(response.data.message, { type: 'success' });
      await refresh();
      return response;
    } catch (err) {
      toast(err.message, { type: "error" });
      return undefined;
    }
  };

  const updateBanner = async (body) => {
    try {
      const response = await axiosClient.put(
        `/backpanel/banner/${updateDetail.detail._id}`,
        body
      );
      toast(response.data.message, { type: 'success' });
      await refresh();
      return response;
    } catch (err) {
      toast(err.message, { type: "error" });
      return undefined;
    }
  };

  const removeBanner = async (id) => {
    const resp = await axiosClient
      .delete(`/backpanel/banner/${id}`)
      .then((response) => {
        toast(response.data.message, { type: "success" });
        refresh();
        return response;
      })
      .catch((err) => toast(err?.data?.message, { type: "error" }));
    return resp;
  };

  const submitBanner = async (newData, type) => {
    if (type === "create") {
      const formData = new FormData();
      formData.append("imagefile", newData.image);
      formData.append("publish", newData.publish);

      const resp = await createBanner(formData);
      if (resp) {
        refresh();
        setIsShowCreate(false);
      }
    } else if (type === "edit") {
      const formData = new FormData();

      formData.append("imagefile", newData.image);
      formData.append("publish", newData.publish);
      formData.append("removeImage", JSON.stringify([]));

      const resp = await updateBanner(formData);

      if (resp) {
        await refresh();
        setUpdateDetail({
          ...updateDetail,
          show: false,
        });
      }
    }
  };

  const handleDragEnd = (e) => {
    if (e.destination) {
      const tempData = Array.from(data);
      const [sourceData] = tempData.splice(e.source.index, 1);
      tempData.splice(e.destination.index, 0, sourceData);
      setData(tempData);
    }
  };

  const ToggleDetail = (index) => {
    const detailList = document.getElementById(`CoverDetail${index}`);
    const detailBtn = document.getElementById(`CoverShowBtn${index}`);
    if (detailList.style.display === "none") {
      detailList.style.display = "contents";
      detailBtn.style.transform = "rotate(180deg)";
      detailBtn.style.transition = "transform 300ms ease-in-out";
    } else {
      detailList.style.display = "none";
      detailBtn.style.transform = "rotate(0deg)";
    }
  };

  const togggleAll = () => {
    data.map((value, index) => ToggleDetail(index));
  };

  const isDifferentSerial = useMemo(() => {
    const bannerString = JSON.stringify(bannerData);
    const dataString = JSON.stringify(data);

    if (bannerString === dataString) {
      return true;
    }
    return false;
  }, [data]);

  const resetBanners = () => {
    setData(bannerData);
  };

  const deleteBanner = (bannerId) => {
    setShowDelAlert({
      id: bannerId,
      show: true,
    });
  };

  const conFirmDelete = async () => {
    const response = await removeBanner(showDelAlert.id);
    if (response) {
      setShowDelAlert({
        id: "",
        show: false,
      });

      await refresh();
    }
  };

  const saveSortedeOrder = async () => {
    const orderKey = data.map((value) => value._id);
    const keyString = orderKey.join(",");
    await axiosClient
      .get(`/backpanel/banners-sort?keys=${keyString}`)
      .then((resp) => {
        toast(resp?.data?.message, { type: "success" });
        refresh();
        return resp;
      })
      .catch((err) => console.log("err", err));
  };

  const editBanner = (bData) => {
    setUpdateDetail({
      show: true,
      detail: bData,
    });
  };

  return (
    <>
      <div className="absolute right-0 top-0 m-5 bg-white">
        <ToastContainer autoClose={3000} />
      </div>
      <CustomAlert
        btnColor="red"
        message="Are you sure want to delete!!!"
        btnLabel="Delete"
        open={showDelAlert.show}
        onClose={() => setShowDelAlert({ ...showDelAlert, show: false })}
        onBtnClick={() => conFirmDelete()}
        type="warn"
      />

      <FormModal
        header="Update Banner"
        isOpen={updateDetail.show}
        title="Update Banner"
      >
        <BannerUpdateForm
          type="editForm"
          bannerData={updateDetail.detail}
          previewId="previewDialog"
          onCancel={() => setUpdateDetail({ ...updateDetail, show: false })}
          onSubmitBanner={(e) => submitBanner(e, "edit")}
        />
      </FormModal>
      <FormModal
        header="Create Banner"
        isOpen={isShowCreate}
        title="Create Banner"
      >
        <BannerCreateForm
          type="createForm"
          previewId="previewDialog"
          onCancel={() => setIsShowCreate(false)}
          onSubmitBanner={(e) => submitBanner(e, "create")}
        />
      </FormModal>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="flex flex-col">
          <div className="mb-5 flex flex-row min-w-[500px] justify-between">
            <PrimaryButton onClicking={() => togggleAll()}>
              <div className="px-5 py-2">
                <BiExpand size={18} />
              </div>
            </PrimaryButton>
            <div className="flex space-x-3">
              {!isDifferentSerial && (
                <PrimaryButton onClicking={() => saveSortedeOrder()}>
                  <div className="px-3 py-2">
                    <GiSave size={18} />
                  </div>
                </PrimaryButton>
              )}
              {!isDifferentSerial && (
                <PrimaryButton onClicking={resetBanners}>
                  <div className="px-3 py-2">
                    <BiReset size={18} />
                  </div>
                </PrimaryButton>
              )}
              <PrimaryButton onClicking={() => setIsShowCreate(true)}>
                <div className="px-3 py-2">
                  <BsPlusCircle size={18} />
                </div>
              </PrimaryButton>
            </div>
          </div>

          <div className="flex flex-row max-w-[1280px]">
            <Droppable droppableId="cardDroppable">
              {(provider) => (
                <div
                  className="space-y-5"
                  ref={provider.innerRef}
                  {...provider.droppableProps}
                >
                  {data &&
                    data.map((banner, index) => (
                      <div
                        key={banner.order}
                        className="w-full rounded-l-[20px] text-sm shadow-md desktop:w-full"
                      >
                        <Draggable
                          key={banner.order}
                          draggableId={`DraggableCard${banner.order}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="flex flex-col desktop:flex-row"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <img
                                className="h-auto w-full rounded-t-[20px] desktop:w-[500px] desktop:rounded-l-[20px] desktop:rounded-tr-[0px]"
                                loader={() => `${banner.imagefile}`}
                                src={`/bucket/motor${banner.imagefile}`}
                                width={1440}
                                height={573}
                                alt="agb Cover"
                              />
                              <div
                                className="w-full"
                                style={{ display: "contents" }}
                                id={`CoverDetail${index}`}
                              >
                                <div className="w-full bg-white px-5 py-2 pb-5">
                                  <div className="flex w-full items-center justify-between space-x-3 border-b border-[#D8D8D8] py-2">
                                    <div className="flex flex-col space-x-2 desktop:flex-row">
                                      <div className={`${styles.labelStyle}`}>
                                        Order No :
                                      </div>
                                      <div>{banner.order}</div>
                                    </div>
                                    <div className="flex flex-col items-center space-x-2 desktop:flex-row">
                                      <div className={`${styles.labelStyle}`}>
                                        Publish :
                                      </div>
                                      <div
                                        className={`${
                                          banner.publish
                                            ? "bg-green-600"
                                            : "bg-red-600"
                                        } rounded-md px-2 py-1 text-white shadow-md`}
                                      >
                                        {banner.publish
                                          ? "Published"
                                          : "Unpublished"}
                                      </div>
                                    </div>

                                    <div className="flex space-x-2">
                                      <PrimaryButton
                                        onClicking={() => {
                                          editBanner(banner);
                                        }}
                                      >
                                        <div className="px-2 py-1">
                                          <FiEdit size={18} />
                                        </div>
                                      </PrimaryButton>
                                      <PrimaryButton
                                        onClicking={() =>
                                          deleteBanner(banner._id)
                                        }
                                        bgColor="red"
                                      >
                                        <div className="px-2 py-1">
                                          <MdDeleteOutline size={18} />
                                        </div>
                                      </PrimaryButton>
                                    </div>
                                  </div>

                                  <div className="flex w-full items-center justify-between border-b border-[#D8D8D8] py-2">
                                    <div className={styles.labelStyle}>
                                      File :
                                    </div>
                                    <div>{banner.imagefile}</div>
                                  </div>
                                </div>
                              </div>
                              <button
                                onClick={() => ToggleDetail(index)}
                                type="button"
                                className="rounded-b-[20px] bg-theme text-xl text-white desktop:rounded-r-[20px] desktop:rounded-bl-[0px]"
                              >
                                <div
                                  className="rotate-90 desktop:rotate-180"
                                  id={`CoverShowBtn${index}`}
                                >
                                  &#10147;
                                </div>
                              </button>
                            </div>
                          )}
                        </Draggable>
                      </div>
                    ))}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </DragDropContext>
    </>
  );
}

BannerComponent.propTypes = {
  bannerData: PropsTypes.arrayOf(
    PropsTypes.shape({
      _id: PropsTypes.string,
      buttonurl: PropsTypes.string,
      content: PropsTypes.string,
      createdAt: PropsTypes.string,
      order: PropsTypes.number,
      title: PropsTypes.string,
      overlayer: PropsTypes.bool,
      publish: PropsTypes.bool,
      imagefile: PropsTypes.string,
    })
  ).isRequired,
  refresh: PropsTypes.func.isRequired,
};

export default BannerComponent;
