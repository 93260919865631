import React from "react";
import PropTypes from "prop-types";

export default function AuthLayout({ children }) {
  return (
    <div className="relative w-full h-auto min-h-screen flex flex-row">
      <div className="w-[100%] tablet:w-full min-h-full h-auto bg-[#000F3CE5]" />
      <div className="absolute top-0 left-0 bottom-0 right-0 tablet:m-auto w-full tablet:w-[95%] laptop:w-[75%] desktop:w-[68%] desktop4k:w-[55%] h-full tablet:h-[70%] laptop:h-[80%] tablet:shadow-[0px_3px_60px_-15px] tablet:shadow-current rounded-xl">
        <div className="flex flex-col tablet:flex-row w-full h-full bg-theme rounded-lg">
          {children}
        </div>
      </div>
      <div className="absolute bottom-3 left-0 w-full h-auto ">
        <p className="text-white text-center caption-font">
          POWERED BY AGB COMMUNICATION
        </p>
      </div>
    </div>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
