/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import propTypes from 'prop-types';

function HookFormInput({
  type,
  placeholder,
  disabled,
  label,
  validate,
  register,
  errors,
  rows,
  cols,
}) {
  return (
    <div>
      <label
        htmlFor={label}
        className={
          type === 'checkbox'
            ? 'relative inline-flex cursor-pointer items-center'
            : 'w-full'
        }
      >
        {type === 'textarea' ? (
          <textarea
            className="w-full min-w-[300px] rounded-md border border-theme px-2 py-2 text-colors-primary outline-theme"
            rows={rows}
            cols={cols}
            {...register(label, validate)}
            placeholder={placeholder}
          />
        ) : (
          <>
            <input
              id={label}
              disabled={disabled}
              className={
                type === 'checkbox'
                  ? 'peer sr-only'
                  : 'w-full rounded-md border border-theme px-2 py-2 text-colors-primary outline-theme'
              }
              type={type}
              placeholder={placeholder}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register(label, validate)}
              aria-invalid={errors[label] ? 'true' : 'false'}
            />
            {type === 'checkbox' && (
              <div className="peer h-6 w-11 rounded-full bg-gray-500 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-[#FFFFFF] after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-[#FFFFFF] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-white dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800" />
            )}
          </>
        )}
      </label>
      {errors && errors[label]?.type === 'required' && (
        <p className="mt-2 text-red-500">This field is required</p>
      )}
    </div>
  );
}

export default HookFormInput;

HookFormInput.propTypes = {
  type: propTypes.string,
  placeholder: propTypes.string.isRequired,
  disabled: propTypes.bool,
  label: propTypes.string.isRequired,
  validate: propTypes.objectOf(
    propTypes.oneOfType([propTypes.bool, propTypes.number])
  ).isRequired,
  register: propTypes.func.isRequired,
  errors: propTypes.objectOf(
    propTypes.oneOfType([propTypes.object, propTypes.string])
  ).isRequired,
};

HookFormInput.defaultProps = {
  type: 'text',
  disabled: false,
};


