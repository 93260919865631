/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import PropTypes from 'prop-types';
import Toggle from "../Toggle";

export default function ColumnControlComponent({
  setColumnOrder,
  allColumns,
  isHidden
}) {
  // table role swap
  const handleDragEnd = (e) => {
    if (e.destination) {
      const tempData = Array.from(allColumns);
      const [sourceData] = tempData.splice(e.source.index, 1);
      tempData.splice(e.destination.index, 0, sourceData);
      setColumnOrder(tempData.map(col => col));
    }

  };
  return (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table className="table-style w-full dark:bg-color-dark_primary">
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <>
                <thead>
                  <tr key='control_title'>
                    <th className="py-2 text-primary"> Columns </th>
                    <th className="py-2 text-primary"> Visible </th>
                  </tr>
                </thead>
                <tbody className="table-body" ref={provider.innerRef} {...provider.droppableProps}>
                  {allColumns &&
                    allColumns
                      .map((col, index) => (
                        <Draggable key={col.accessor} draggableId={col.accessor} index={index}>
                          {(provided) => (
                            <tr
                              className="shadow-gray-500 shadow-sm hover:bg-theme hover:text-colors-secondary dark:bg-color-dark_secondary"
                              key={`draggable_row${col.accessor}`}
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                            >
                              <td
                                align="center"
                                className="w-6/12"
                                style={{
                                  padding: "10px",
                                }}
                                {...provided.dragHandleProps}
                              >
                                {" "}
                                {col.Header}{" "}
                              </td>
                              <td align="center">
                                <Toggle
                                  id={col.accessor}
                                  checked={col.show}
                                  handleOnChange={() => isHidden(index, !col.show)}
                                />
                              </td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                  {provider.placeholder}
                </tbody>
              </>
            )}
          </Droppable>
        </table>
      </DragDropContext>
    </div>
  );
}

ColumnControlComponent.propTypes = {
  allColumns: PropTypes.PropTypes.arrayOf(PropTypes.shape({
    Cell: PropTypes.func,
    Header: PropTypes.string,
    accessor: PropTypes.string,
    filter: PropTypes.bool,
    show: PropTypes.bool,
    width: PropTypes.number
  })).isRequired,
  setColumnOrder: PropTypes.func.isRequired,
  isHidden: PropTypes.func.isRequired,
};