import React from "react";
import axios from "axios";
import { BiLogOutCircle } from "react-icons/bi";
import { useToggle } from "../provider/context";

import axiosClient from "../../axios-client";
import { useNavigate } from "react-router-dom";
export default function TopNavigation() {
  const { toggle, ref } = useToggle();
  const navigate = useNavigate();

  return (
    <header
      className={`relative z-10 h-20 w-full items-center ${"bg-[#000F3C]"} `}
    >
      <div className="relative mx-auto flex h-full flex-col justify-center px-3">
        <div className="laptop:max-w-68 relative ml-0 flex w-full items-center pl-1 pr-2">
          <div className="relative left-0 flex w-3/4">
            <div className="group relative flex h-full w-12 items-center">
              <button
                type="button"
                aria-expanded="false"
                aria-label="Toggle sidenav"
                onClick={toggle}
                ref={ref}
                className="text-4xl text-white focus:outline-none laptop:hidden"
              >
                &#8801;
              </button>
            </div>
          </div>
          <div className="relative right-auto ml-5 mr-0 flex w-full items-center justify-end p-1">
            <button
              type="button"
              onClick={() => {
                axiosClient
                  .post(`/backpanel/logout`)
                  .then((res) => {
                    localStorage.removeItem("access_token");

                    navigate("/login");
                  })
                  .catch((e) => console.log(e));
              }}
              className="block pr-5"
            >
              <BiLogOutCircle className="text-white" size={25} />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}
