import React from "react";

import { LuActivitySquare } from "react-icons/lu";

import ImageIcon from "./icons/medias";
import { BiCategory } from "react-icons/bi";
import { FaSquareYoutube } from "react-icons/fa6";

import { FaRegistered } from "react-icons/fa";

const data = [
  {
    title: "Banner",
    icon: <ImageIcon />,
    link: "/banner",
  },

  {
    title: "Career",
    icon: <LuActivitySquare size={23} />,
    link: "/career",
  },

  {
    title: "News and Events",
    icon: <FaSquareYoutube size={23} />,
    link: "/news",
  },

  {
    title: "Register",
    icon: <FaRegistered size={23} />,
    link: "/register",
  },
];

export default data;
