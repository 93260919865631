import React, { useEffect, useContext } from "react";
import axiosClient from "../../axios-client";
import axios from "axios";
import BannerComponent from "../../components/BannerComponent";

import { Navigate } from "react-router-dom";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import ErrContext from "../../providers/ErrorProvider";
import { setupAxiosInterceptors } from "../../axios-client";
import MainLoading from "../../components/MainLoading";
const Banner = () => {
  // const [setIsError, setErrMsg] = useContext(ErrContext);

  const fetcher = async (url) => {
    try {
      const response = await axiosClient.get(url);

      return response?.data?.banners;
    } catch (err) {
      // setIsError(true);
      // setErrMsg({ code: err.response?.status, msg: err.message });
      return undefined;
    }
  };

  const { data: banners, isLoading } = useSWR(`/backpanel/banners`, fetcher);
  const { trigger, isMutating } = useSWRMutation(`backpanel/banners`, fetcher);

  return (
    <>
      <MainLoading bg="#000F3C4D" enter={isMutating} />
      <div className="flex justify-center">
        {banners && (
          <BannerComponent
            bannerData={banners}
            refresh={() => trigger(banners)}
          />
        )}
      </div>
    </>
  );
};

export default Banner;
