import React from "react";
import PropTypes from "prop-types";
import styles from "./style.module.css";

function LoadingComponent({ themeColor }) {
  return (
    <div className="relative bg-transparent flex justify-center items-center">
      <div className="flex flex-col justify-center items-center">
        <div className={`${styles.loadContainer}`}>
          <div className="bg-theme" style={{ backgroundColor: themeColor }} />
          <div className="bg-theme" style={{ backgroundColor: themeColor }} />
          <div className="bg-theme" style={{ backgroundColor: themeColor }} />
        </div>
      </div>
    </div>
  );
}

export default LoadingComponent;

LoadingComponent.propTypes = {
  themeColor: PropTypes.string,
};

LoadingComponent.defaultProps = {
  themeColor: "",
};
