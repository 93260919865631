// import axios from "axios";
// import { jwtDecode } from "jwt-decode";

// const axiosClient = axios.create({
//   baseURL: "/",
// });

// axiosClient.interceptors.request.use(
//   async (config) => {
//     const access_token = localStorage.getItem("access_token");

//     if (access_token && access_token !== "undefined") {
//       const decodedToken = jwtDecode(access_token);
//       const currentTime = Date.now() / 1000;

//       if (decodedToken.exp < currentTime) {
//         try {
//           const response = await axios.get("/backpanel/swap-token", {
//             withCredentials: true,
//           });

//           if (response.data?.tokens?.accessToken) {
//             localStorage.setItem(
//               "access_token",
//               response?.data?.tokens?.accessToken
//             );
//             config.headers["secret_key"] = response?.data?.tokens?.accessToken;
//           } else {
//             throw new Error("Invalid token response");
//           }
//         } catch (error) {
//           console.error("Failed to refresh token:", error);
//           localStorage.removeItem("access_token");
//           window.location.replace("login");
//           return Promise.reject(error);
//         }
//       } else {
//         console.error("Failed to refresh token::", error);
//         config.headers["secret_key"] = access_token;
//       }
//     } else {
//       console.error("Failed to refresh token:::", error);
//       localStorage.removeItem("access_token");
//       window.location.replace("login");
//     }

//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// export default axiosClient;

import axios from "axios";
import { jwtDecode } from "jwt-decode";

const axiosClient = axios.create({
  baseURL: "/",
});

axiosClient.interceptors.request.use(
  async (config) => {
    const access_token = localStorage.getItem("access_token");

    if (access_token && access_token !== "undefined") {
      const decodedToken = jwtDecode(access_token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        try {
          const response = await axios.get("/backpanel/swap-token", {
            withCredentials: true,
          });

          if (response.data?.tokens?.accessToken) {
            localStorage.setItem(
              "access_token",
              response?.data?.tokens?.accessToken
            );
            config.headers["secret_key"] = response?.data?.tokens?.accessToken;
          } else {
            throw new Error("Invalid token response");
          }
        } catch (error) {
          console.error("Failed to refresh token:", error);
          localStorage.removeItem("access_token");
          window.location.replace("login");
          return Promise.reject(error);
        }
      } else {
        config.headers["secret_key"] = access_token;
      }
    } else {
      localStorage.removeItem("access_token");
      window.location.replace("login");
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosClient;
