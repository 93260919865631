/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
  Fragment,
  useCallback,
  useLayoutEffect,
  useEffect,
} from "react";
import { useExpanded, useSortBy, useTable, useColumnOrder } from "react-table";
import { BiChevronsRight } from "react-icons/bi";
import { FaFileExport, FaFileCsv } from "react-icons/fa";
import { TiThMenu } from "react-icons/ti";
import { TbSortAscending, TbSortDescending } from "react-icons/tb";
import { RiRefreshFill } from "react-icons/ri";
import PropTypes from "prop-types";
import ColumnControlComponent from "./Components/ColumnControlComponent";
import ExpandableComponent from "./Components/ExpandableComponent";

import PrimaryButton from "../Buttons/PrimaryButton";
import PaginationComponent from "./Components/PaginationComponent";
import DialogBox from "../ModalBox/DialogBox";
import tableStyle from "../../styles/table.module.css";
import FilterComponent from "./Components/FilterComponent";

function ExpanderHeader({
  toggleAllRowsExpanded,
  isAllRowsExpanded,
  rows,
  openAllExpand,
}) {
  return (
    <button
      type="button"
      id="expand-all-btn"
      onClick={() => {
        toggleAllRowsExpanded(!isAllRowsExpanded);
        openAllExpand(rows, isAllRowsExpanded);
      }}
    >
      {" "}
      <BiChevronsRight size={20} />
    </button>
  );
}

ExpanderHeader.propTypes = {
  toggleAllRowsExpanded: PropTypes.func.isRequired,
  isAllRowsExpanded: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
    PropTypes.array,
    PropTypes.func,
  ]).isRequired,
  openAllExpand: PropTypes.func.isRequired,
};

function ExpanderRow({ row, openExpand }) {
  return (
    <button
      type="button"
      id={`expandBtn${row.index}`}
      onClick={() => openExpand(row.index, row.original)}
      className="py2 h-auto w-fit rounded-sm bg-transparent px-2 text-white"
    >
      <BiChevronsRight
        size={20}
        id={`expandIcon${row.index}`}
        className="text-theme"
      />
    </button>
  );
}

ExpanderRow.propTypes = {
  row: PropTypes.objectOf([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
    PropTypes.array,
    PropTypes.func,
  ]).isRequired,
  openExpand: PropTypes.func.isRequired,
};

function CustomizedTable({
  dataRows,
  dataColumns,
  multiSelectValue,
  setMultiSelectValue,
  filteredTargets,
  setFilteredTargets,
  defaultFilteredTargetValues,
  pagination,
  totalItems,
  page,
  setPage,
  limit,
  setLimit,
  sortOrder,
  setSortOrder,
  handleFilter,
  handleSort,
  clearSearch,
  loading,
  progressComponent,
  exportExternal,
  searchText,
  setSearchText,
  onCreate,
  refreshRow,
  create,
  routeName,
}) {
  const [modalControl, setModalControl] = useState(false);
  const [expandedRow, setExpandedRow] = useState(0);
  const [expandColumnOrder, setExpandColumnOrder] = useState([
    { order: "", show: true },
  ]);
  const [width, setWidth] = useState(window.innerWidth);
  const [globalColumn, setGlobalColumn] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);

  const updateSize = () => {
    setWidth(window.innerWidth);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
  }, []);

  const changeGlobal = async () => {
    const defaultGlobal = dataColumns?.map((col) => {
      const tempColumn = col;
      tempColumn.show = true;
      return tempColumn;
    });
    await setGlobalColumn(defaultGlobal);
  };

  useEffect(() => {
    changeGlobal();
  }, [dataColumns]);

  const openExpand = (e) => {
    setExpandedRow(e);
    const expand = document.getElementById(`expand${e}`);
    const icon = document.getElementById(`expandIcon${e}`);
    if (expand.style.display === "none") {
      expand.style.display = "contents";
      icon.style.transform = "rotate(90deg)";
      icon.style.transition = "0.3s";
      return;
    }
    expand.style.display = "none";
    icon.style.transform = "rotate(0deg)";
    icon.style.transition = "0.3s";
  };

  const openAll = (e) => {
    const expand = document.getElementById(`expand${e}`);
    const icon = document.getElementById(`expandIcon${e}`);
    expand.style.display = "contents";
    icon.style.transform = "rotate(90deg)";
    icon.style.transition = "0.3s";
  };

  const closeAll = (e) => {
    const expand = document.getElementById(`expand${e}`);
    const icon = document.getElementById(`expandIcon${e}`);
    expand.style.display = "none";
    icon.style.transform = "rotate(0deg)";
    icon.style.transition = "0.3s";
  };

  const openAllExpand = useCallback((e, isAllRowsExpanded) => {
    const rowsLength = e.length;
    if (!isAllRowsExpanded) {
      const getExpandAllBtn = document.getElementById("expand-all-btn");
      getExpandAllBtn.style.transform = "rotate(90deg)";
      getExpandAllBtn.style.transition = "0.3s";
      for (let i = 0; i < rowsLength; i += 1) {
        openAll(i);
      }
    } else {
      const getExpandAllBtn = document.getElementById("expand-all-btn");
      getExpandAllBtn.style.transform = "rotate(0deg)";
      getExpandAllBtn.style.transition = "0.3s";
      for (let i = 0; i < rowsLength; i += 1) {
        closeAll(i);
      }
    }
  }, []);

  const [columnStates, setColumnStates] = useState([
    {
      id: "expander",

      Header: ({ toggleAllRowsExpanded, isAllRowsExpanded, rows }) =>
        ExpanderHeader({
          toggleAllRowsExpanded,
          isAllRowsExpanded,
          rows,
          openAllExpand,
        }),

      Cell: ({ row }) => ExpanderRow({ row, openExpand }),
      show: true,
      width: 50,
    },
    ...globalColumn,
  ]);

  const isHideCol = () => {
    const previousCol = [];
    globalColumn.map((value) => {
      if (value.show === false) {
        previousCol.push(value.accessor);
      }
      return null;
    });
    if (JSON.stringify(previousCol) !== JSON.stringify(hiddenColumns)) {
      setHiddenColumns(previousCol);
    }
  };

  useEffect(() => {
    const tableWrapper = document.getElementById("customize-table");
    const wrapperWidth = tableWrapper.offsetWidth;
    const colNo = dataColumns?.length;
    const colWithFixedWidth = dataColumns?.map((value) =>
      value.width ? value.width : 0
    );
    const fixedWidthTotal = colWithFixedWidth?.reduce((a, b) => a + b, 0);
    // const widthLeft = wrapperWidth - fixedWidthTotal;
    const colWithWidth = dataColumns?.filter((value) => value.width && value);
    const colWithoutWidthTotal = colNo - colWithWidth?.length;
    // const averageColWidth = widthLeft / colWithoutWidthTotal;
    let approximateTableWidth = fixedWidthTotal + 150 * colWithoutWidthTotal;
    // let lastCellWidth = globalColumn[(globalColumn.length) - 1].width ? globalColumn[(globalColumn.length) - 1].width : 150;
    let i = 0;
    const expandHeader = [];
    let generatedCol = globalColumn;
    if (approximateTableWidth > wrapperWidth) {
      // console.log('narrow', wrapperWidth);
      do {
        i += 1;
        expandHeader.unshift({
          order: globalColumn[globalColumn?.length - i]?.accessor,
          Header: globalColumn[globalColumn?.length - i]?.Header,
          show: globalColumn[globalColumn?.length - i]?.show,
        });
        setExpandColumnOrder(expandHeader);
        generatedCol = generatedCol.slice(0, globalColumn?.length - i);
        approximateTableWidth -= globalColumn[i]?.width
          ? globalColumn[i].width
          : 150;
      } while (approximateTableWidth > wrapperWidth);

      setColumnStates([
        {
          id: "expander",

          Header: ({ toggleAllRowsExpanded, isAllRowsExpanded, rows }) =>
            ExpanderHeader({
              toggleAllRowsExpanded,
              isAllRowsExpanded,
              rows,
              openAllExpand,
            }),

          Cell: ({ row }) => ExpanderRow({ row, openExpand }),
          show: true,
          width: 50,
        },
        ...generatedCol,
      ]);
      isHideCol();
    } else {
      setExpandColumnOrder([{ order: "", show: true }]);
      setColumnStates([
        {
          id: "expander",

          Header: ({ toggleAllRowsExpanded, isAllRowsExpanded, rows }) =>
            ExpanderHeader({
              toggleAllRowsExpanded,
              isAllRowsExpanded,
              rows,
              openAllExpand,
            }),

          Cell: ({ row }) => ExpanderRow({ row, openExpand }),
          show: true,
          width: 50,
        },
        ...generatedCol,
      ]);
      isHideCol();
    }
  }, [width, hiddenColumns, globalColumn]);

  const checkIdInclude = (oldCol, newCol) => {
    let check = true;
    newCol.map((value) => {
      if (oldCol.includes(value.accessor) === false) {
        check = false;
      }
      return null;
    });
    return check;
  };

  const columns = React.useMemo(() => columnStates, [columnStates]);
  const data = React.useMemo(() => dataRows, [dataRows]);

  const tableInstance = useTable(
    { columns, data, manualPagination: true, initialState: { hiddenColumns } },
    useSortBy,
    useExpanded,
    useColumnOrder
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setColumnOrder,
  } = tableInstance;

  const colHideHandler = async (acc, e) => {
    await setGlobalColumn(
      globalColumn.map((value, index) =>
        index === acc ? { ...value, show: e } : value
      )
    );
    if (acc >= allColumns.length - 1) {
      return;
    }
    allColumns[acc + 1].toggleHidden(!e);
  };

  const arrangeColOrder = async (e) => {
    let tableIds = allColumns.map((value) => value.id);
    tableIds = tableIds.slice(1, tableIds.length);
    const orderIds = e.slice(0, tableIds.length);
    if (checkIdInclude(tableIds, orderIds) === true) {
      setColumnOrder(["expander", ...e.map((value) => value.accessor)]);
      await setGlobalColumn(e);
      return;
    }
    await setGlobalColumn(e);
  };

  const startItem =
    Number(page) === 1 ? page : 1 + (Number(page) - 1) * Number(limit);
  const endItem =
    Number(page) * Number(limit) >= totalItems
      ? totalItems
      : Number(page) * Number(limit);

  return (
    <>
      <DialogBox
        title="Column Control"
        onCloseModal={() => setModalControl(false)}
        isOpen={modalControl}
      >
        <ColumnControlComponent
          allColumns={globalColumn}
          setColumnOrder={(e) => arrangeColOrder(e)}
          isHidden={(acc, e) => colHideHandler(acc, e)}
        />
      </DialogBox>
      {/* <div className="relative mt-2 flex flex-row items-center justify-between space-x-2">
        <div className="flex w-full items-center justify-end tablet:w-[50%] laptop:w-[40%] desktop:w-[20%]">
          <input
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                refreshRow();
              }
            }}
            className="border-theme_border text-color-primary dark:bg-color-dark_primary dark:text-color-dark_secondary m-1 h-12 w-full rounded-md border p-3 outline-none focus:border-2"
            placeholder="Search ..."
          />
          <button
            type="button"
            onClick={refreshRow}
            className="transition-all duration-500 hover:rotate-[360deg]"
          >
            <RiRefreshFill size={35} color="#000F3C" />
          </button>
        </div>
      </div>
      <h3 className="text-center text-base text-colors-primary tablet:hidden">
        {" "}
        Showing {totalItems === 0 ? 0 : startItem} to {endItem} of {totalItems}{" "}
        results
      </h3> */}
      <div className="relative mt-2 flex flex-row items-center justify-between space-x-2">
        <div className="flex items-center justify-center gap-2">
          <PrimaryButton onClicking={onCreate}>
            <div className="px-2 py-3">Create</div>
          </PrimaryButton>
        </div>
      </div>
      <div
        id="customize-table"
        className="mt-4 rounded-lg border border-theme shadow-md"
      >
        <table className={tableStyle.table_style} {...getTableProps()}>
          <thead className="bg-[#000f3c]">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      style: { width: column.width },
                    })}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`${tableStyle.table_head_column} dark:text-color-dark_primary text-[#fff] `}
                  >
                    <span className="flex flex-row items-center justify-center px-2 text-center text-sm">
                      {column.render("Header")}
                      <span>
                        {column.isSorted
                          ? (column.isSortedDesc && (
                              <TbSortDescending size={20} color="#FFFFFF" />
                            )) || <TbSortAscending size={20} color="#FFFFFF" />
                          : ""}
                      </span>
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {loading === true ? (
            <tbody>
              <tr>
                <td colSpan="10" className="width-full">
                  <div className="flex h-full w-full flex-row justify-center">
                    {progressComponent}
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody
              className={`${tableStyle.table_body}`}
              {...getTableBodyProps()}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={row.index}>
                    <tr
                      className="hover:bg-theme_hover dark:hover:bg-color-dark_primary dark:hover:text-color-dark_primary dark:bg-color-dark_secondary"
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => (
                        <td
                          className="dark:text-color-dark_secondary px-2 py-2 text-[#000F3C]"
                          {...cell.getCellProps({
                            style: {
                              width: cell.column.width,
                            },
                          })}
                        >
                          <p className="line-clamp-1">{cell.render("Cell")}</p>
                        </td>
                      ))}
                    </tr>

                    <tr id={`expand${row.index}`} style={{ display: "none" }}>
                      <td colSpan={row.cells.length}>
                        <ExpandableComponent
                          expandOrder={expandColumnOrder}
                          openIndex={expandedRow}
                          data={data}
                        />
                      </td>
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className="flex items-center justify-between">
        <div className="hidden w-full items-start justify-center tablet:flex tablet:w-[50%] tablet:flex-col laptop:w-[calc(100%-33%)] desktop:w-[calc(100%-17%)]">
          <span className="text-base text-colors-primary">
            {" "}
            Showing {totalItems === 0 ? 0 : startItem} to {endItem} of{" "}
            {totalItems} Results
          </span>
        </div>
        {pagination && (
          <div className="mt-5 flex w-full flex-row justify-end">
            <PaginationComponent
              page={page}
              setPage={setPage}
              totalRows={totalItems}
              limit={limit}
              setLimit={setLimit}
            />
          </div>
        )}
      </div>

      {/* <div className="mt-5 w-[20%]">
        <div className="flex basis-1/4 flex-row space-x-2">
          {!pathname.includes("tutorial") &&
            !pathname.includes("review") &&
            !pathname.includes("checkavailable") &&
            !pathname.includes("referalProgram") &&
            !pathname.includes("faq") && (
              <>
                <PrimaryButton onClicking={() => exportExternal("xlsx")}>
                  <div className="flex flex-row items-center justify-center space-x-2 px-2 py-2">
                    <FaFileExport />
                    <span>Excl</span>
                  </div>
                </PrimaryButton>
                <PrimaryButton onClicking={() => exportExternal("csv")}>
                  <div className="flex flex-row items-center justify-center space-x-2 px-2 py-2">
                    <FaFileCsv />
                    <span>Csv</span>
                  </div>
                </PrimaryButton>
              </>
            )}
          {!pathname.includes("review") && !pathname.includes("faq") && (
            <PrimaryButton onClicking={() => setModalControl(!modalControl)}>
              <div className="bssis-3/4">
                <div className="flex flex-row items-center justify-center px-2 py-2">
                  <TiThMenu />
                </div>
              </div>
            </PrimaryButton>
          )}
        </div>
      </div> */}
    </>
  );
}

export default CustomizedTable;
