import { useRef, useState } from "react";
import _ from "lodash";

export function Hook() {
  const userNameInput = useRef(null);
  const passwordInput = useRef(null);

  const DEFAULT_INPUT = {
    userName: {
      valueInput: "",
      errorText: "",
    },
    password: {
      valueInput: "",
      errorText: "",
    },
  };

  const [input, setInput] = useState(DEFAULT_INPUT);
  const [isShownPassword, setIsShownPassword] = useState(false);

  // #region - Event Handler
  const clickOnEyeIcons = () => {
    setIsShownPassword((curr) => !curr);
  };
  const handleChangeOnInput = (e) => {
    let tempInput = _.cloneDeep(input);
    const { name } = e.target;
    const { value } = e.target;

    switch (name) {
      case "userInput":
        tempInput = {
          ...tempInput,
          userName: {
            ...tempInput.userName,
            valueInput: value,
            errorText: "",
          },
        };
        setInput(tempInput);
        break;
      case "passwordInput":
        tempInput = {
          ...tempInput,
          password: {
            ...tempInput.password,
            valueInput: value,
            errorText: "",
          },
        };
        setInput(tempInput);
        break;
      default:
        break;
    }
  };

  return [
    input,
    isShownPassword,
    userNameInput,
    passwordInput,
    /**
     * action
     */
    clickOnEyeIcons,
    handleChangeOnInput,
  ];
}
