import React, { useEffect, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import PropTypes from "prop-types";
import styles from "../../../../src/styles/table.module.css";

function PaginationComponent({ page, setPage, totalRows, limit, setLimit }) {
  const [canGoNext, setCanGoNext] = useState(true);
  const [canGoBack, setCanGoBack] = useState(true);
  const [noOfPages, setNoOfPages] = useState(0);
  const [pageArr, setPageArr] = useState([]);

  const onNextPage = () => setPage(page + 1);
  const onPrevPage = () => setPage(page - 1);
  const onPageSelect = (e) => setPage(e);

  useEffect(() => {
    if (limit && totalRows) {
      setLimit(limit);
      const pages = Math.ceil(totalRows / Number(limit));

      if (page > pages) {
        setPage(pages);
      }
      if (pages === 0) {
        setPage(1);
      }
      setNoOfPages(pages);
      setPageArr([...new Array(pages)]);
    }
  }, [limit, totalRows]);

  useEffect(() => {
    if (noOfPages === page || noOfPages === 0) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }

    if (page === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, page]);

  useEffect(() => {
    setPageArr([...new Array(noOfPages)]);
  }, [noOfPages]);
  // console.log('page Arr', pageArr);
  return (
    <div>
      <div className="flex flex-row items-center">
        <div className="hidden text-colors-primary tablet:block">
          page {page} of {pageArr.length}
        </div>
        <button
          type="button"
          disabled={!canGoBack}
          className="bg-blue-gray-100 mx-3 hidden rounded-md border border-theme py-2 tablet:block"
          onClick={onPrevPage}
        >
          <BiChevronLeft className="text-colors-primary" size={25} />
        </button>
        <div>
          {pageArr.length < 5 ? (
            pageArr.map((value, index) => (
              <button
                type="button"
                key={`paginage_btn${index + 1}`}
                onClick={() => onPageSelect(index + 1)}
                className={`${
                  page === index + 1 ? styles.active_btn_nova : ""
                } bg-blue-gray-100 mx-1 rounded-md border border-theme px-2 py-2 text-colors-primary`}
              >
                {index + 1}
              </button>
            ))
          ) : (
            <div className="flex flex-row ">
              <div>
                <button
                  type="button"
                  key={`paginage_btn${1}`}
                  onClick={() => onPageSelect(1)}
                  className={`${
                    page === 1 ? `${styles.active_btn}bg-theme` : ""
                  } bg-blue-gray-100 mx-1 rounded-md border border-theme px-2 py-2 text-colors-primary`}
                >
                  {1}
                </button>
              </div>
              <span className="flex flex-col justify-end text-colors-primary">
                {" "}
                ...{" "}
              </span>
              {page - 1 > 1 && (
                <button
                  type="button"
                  key={`paginage_btn${page - 1}`}
                  onClick={() => onPageSelect(page - 1)}
                  className="bg-blue-gray-100 mx-1 rounded-md border border-theme px-2 py-2 text-colors-primary"
                >
                  {page - 1}
                </button>
              )}
              {page > 1 && page < pageArr.length && (
                <button
                  type="button"
                  key={`paginage_btn${page}`}
                  onClick={() => onPageSelect(page)}
                  className={`${styles.active_btn} bg-blue-gray-100 mx-1 rounded-md border border-theme px-2 py-2 text-colors-primary`}
                >
                  {page}
                </button>
              )}
              {page + 1 < pageArr.length && (
                <button
                  type="button"
                  key={`paginage_btn${page + 1}`}
                  onClick={() => onPageSelect(page + 1)}
                  className="bg-blue-gray-100 mx-1 rounded-md border border-theme px-2 py-2 text-colors-primary"
                >
                  {page + 1}
                </button>
              )}
              <span className="flex flex-col justify-end text-colors-primary">
                {" "}
                ...{" "}
              </span>
              {pageArr.length > 2 && (
                <div>
                  <button
                    type="button"
                    key={`paginage_btn${pageArr.length}`}
                    onClick={() => onPageSelect(pageArr.length)}
                    className={`${
                      pageArr.length === page ? styles.active_btn_nova : ""
                    } bg-blue-gray-100 mx-1 rounded-md px-2 py-2 text-colors-primary`}
                  >
                    {pageArr.length}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        <button
          type="button"
          disabled={!canGoNext}
          className="bg-blue-gray-100 mx-3 hidden rounded-md border border-theme py-2 tablet:block"
          onClick={onNextPage}
        >
          <BiChevronRight className="text-colors-primary" size={25} />
        </button>
        <select
          className="bg-blue-gray-100 rounded-md border border-theme px-2 py-2 text-colors-primary"
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
        >
          {[10, 20, 30, 40, 50].map((size) => (
            <option value={size} key={`pagesizeopt${size}`}>
              {" "}
              {size}{" "}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default PaginationComponent;

PaginationComponent.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalRows: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
};
