import React from "react";
import PropTypes from "prop-types";

function OrdinaryCard({ children, BadgeLabel }) {
  return (
    <div
        className="relative w-full h-auto dark:bg-color-dark_primary border border-[#B6B6B6] px-5 py-3 rounded-xl shadow-lg duration-200 md:cursor-pointer text-color-primary"
      >
        {children}
        {BadgeLabel && BadgeLabel}
      </div>
  );
}

export default OrdinaryCard;

OrdinaryCard.propTypes = {
  children: PropTypes.element.isRequired,
  BadgeLabel: PropTypes.element,
};

OrdinaryCard.defaultProps = {
  BadgeLabel : null
}
