import React, { useRef } from 'react'
import { FiCheckSquare } from 'react-icons/fi'
import { AiOutlineWarning } from 'react-icons/ai'
import { ImCancelCircle } from 'react-icons/im'
import { CSSTransition } from 'react-transition-group'
import propTypes from 'prop-types'
import PrimaryButton from '../Buttons/PrimaryButton'
import styles from './style.module.css'

function CustomAlert({
    onClose,
    type = "success",
    message,
    onBtnClick,
    open,
    btnColor,
    btnLabel
}) {
    const alertRef = useRef();
    return (
        <CSSTransition classNames={{
            enterActive: styles.alertEnterActive,
            enterDone: styles.alertEnterDone,
            exitActive: styles.alertExitActive,
            exitDone: styles.alertExitDone,
        }} in={open} nodeRef={alertRef} mountOnEnter unmountOnExit timeout={200}>
            <div className='fixed w-full h-full top-0 left-0 z-50 bg-black/10 flex justify-center items-center'>
                <div ref={alertRef} className='w-[320px] h-[180px] laptop:w-[400px] bg-white shadow-md border border-theme rounded-lg py-3 px-5 flex flex-col justify-around'>
                    <div className='w-full h-[70%] flex flex-col justify-center items-center'>
                        <div className='h-[50%]'>
                            {
                                type === 'success' && (
                                    <FiCheckSquare size={50} color='green' />
                                )
                            }
                            {
                                type === 'warn' && (
                                    <AiOutlineWarning size={50} color='orange' />
                                )
                            }
                            {
                                type === 'fail' && (
                                    <ImCancelCircle size={50} color='red' />
                                )
                            }
                        </div>
                        <p className='h-[50%] text-base font-semibold text-colors-primary flex justify-center items-center'>{message}</p>
                    </div>
                    <div className='w-full h-[20%] flex justify-end space-x-2'>
                        <PrimaryButton
                            onClicking={onClose}>
                            <div className='px-3 py-2'>
                                Close
                            </div>
                        </PrimaryButton>
                        <PrimaryButton
                            bgColor={btnColor}
                            onClicking={onBtnClick || (() => console.log("btn click"))}>
                            <div className='px-3 py-2'>
                                {btnLabel}
                            </div>
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </CSSTransition>
    )
}

CustomAlert.propTypes = {
    onClose: propTypes.func.isRequired,
    type: propTypes.string,
    message: propTypes.string.isRequired,
    onBtnClick: propTypes.func,
    open: propTypes.bool.isRequired,
    btnColor: propTypes.string.isRequired,
    btnLabel: propTypes.string.isRequired
}

CustomAlert.defaultProps = {
    type: "success",
    onBtnClick: null
};

export default CustomAlert