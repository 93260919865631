import React, { useEffect, useRef, useState } from "react";
import propTypes from "prop-types";
import { useForm } from "react-hook-form";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa6";
import PrimaryButton from "../Buttons/PrimaryButton";

import axiosClient from "../../axios-client";

function UserEditForm({ type, id, onConfirm, onClose, formData }) {
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: type === "edit_form" ? formData.username : "",
      fullname: type === "edit_form" ? formData.fullname : "",
      email: type === "edit_form" ? formData.email : "",
      password: type === "edit_form" ? formData.password : "",
      brand: type === "edit_form" ? formData.brand : [],
      permissions: type === "edit_form" ? formData.permissions : [],
    },
    mode: "onChange",
  });
  const [isSuperAdmin, setIsSuperAdmin] = useState(
    formData?.permissions?.includes("superadmin") ? true : false
  );
  const [changePassword, setChangePassword] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [showPwd, setShowPwd] = useState(false);
  //  get permissions
  const fetchData = async () => {
    try {
      const response = await axiosClient.get(`/backpanel/permissions`);

      const data = response.data;

      setPermissions(data?.PERMISSIONS_LIST_BY_PAGE || []);
    } catch (err) {
      //   setIsError(true);
      //   setErrMsg({ code: err.response.status, msg: err.message });
    }
  };

  useEffect(() => {
    fetchData();
    setValue("username", formData?.username);
    setValue("fullname", formData?.fullname);
    setValue("email", formData?.email);
    // if (formData?.permissions) {
    //   formData.permissions.forEach((permission) => {
    //     setValue(permission, true);
    //   });
    // }
  }, [formData, type, setValue]);

  const generateRandomString = (length) => {
    const alphabetString =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890!@#$%^&*()";
    const max = alphabetString.length - 1;
    const min = 0;
    let res = "";
    while (length-- > 0) {
      const index = Math.floor(Math.random() * (max - min + 1)) + min;
      res += alphabetString.charAt(index);
    }
    setValue("password", res);

    return res;
  };

  const onSubmit = async (data, id) => {
    const requestBody = {
      _id: id,
      username: data.username,
      email: data.email,
      fullname: data.fullname,
      // permissions: [],
      permissions: isSuperAdmin ? ["superadmin"] : selectedPermission,
      ...(changePassword && { password: data.password }),
    };

    onConfirm(requestBody, id);
  };

  const handleSwitchPwdToggle = (event) => {
    setChangePassword(event.target.checked);
  };

  useEffect(() => {
    fetchData();
    if (formData?.permissions) {
      setSelectedPermission(formData.permissions);
    }
  }, [formData, type]);

  const handlePermissionClick = (permission) => {
    setSelectedPermission((prev) =>
      prev.includes(permission) ? prev.filter((item) => item !== permission) : [...prev, permission]
    );
  };

  const handleSwitchToggle = (event) => {
    setIsSuperAdmin(event.target.checked);
    if (event.target.checked) {
      setSelectedPermission([]);
    }
  };

  return (
    <div className="sticky top-0 w-full px-5 py-5">
      <div className="w-full space-y-7 rounded-[30px] border border-theme bg-white px-7 py-7 shadow-md">
        {/* form */}
        <form className="space-y-6">
          {/* full name */}
          <div>
            <div className="flex flex-row items-center">
              <div className="flex flex-col w-[15%]">Full Name</div>
              <div className="flex flex-col w-[60%]">
                <input
                  className="rounded-md border border-theme px-2 py-2 text-colors-primary outline-theme"
                  {...register("fullname", {
                    required: "This field is required",
                  })}
                />
              </div>
            </div>
            {errors.fullname && (
              <div className="ml-[15%] flex flex-row text-red-500 font-semibold">
                {errors.fullname.message}
              </div>
            )}
          </div>

          {/* user name */}
          <div>
            <div className="flex flex-row items-center">
              <div className="flex flex-col w-[15%]">Username</div>
              <div className="flex flex-col w-[60%]">
                <input
                  className="rounded-md border border-theme px-2 py-2 text-colors-primary outline-theme"
                  {...register("username", {
                    required: "This field is required",
                  })}
                />
              </div>
            </div>
            {errors.username && (
              <div className="ml-[15%] flex flex-row text-red-500 font-semibold">
                {errors.username.message}
              </div>
            )}
          </div>

          {/* email */}
          <div>
            <div className="flex flex-row items-center">
              <div className="flex flex-col w-[15%]">Email</div>
              <div className="flex flex-col w-[60%]">
                <input
                  className="rounded-md border border-theme px-2 py-2 text-colors-primary outline-theme"
                  {...register("email", { required: "This field is required" })}
                />
              </div>
            </div>
            {errors.email && (
              <div className="ml-[15%] flex flex-row text-red-500 font-semibold">
                {errors.email.message}
              </div>
            )}
          </div>

          <div className="flex items-center">
            <div className="flex flex-col w-[15%]">Change Password?</div>
            <div className="flex items-center space-x-3">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  placeholder="password"
                  label="password"
                  checked={changePassword}
                  onChange={handleSwitchPwdToggle}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
          </div>
          {changePassword && (
            <>
              {/* pwd */}
              <div>
                <div className="flex flex-row items-center">
                  <div className="flex flex-col w-[15%]">Password</div>
                  <div className="flex flex-col w-[60%] relative">
                    {showPwd ? (
                      <>
                        <input
                          type="text"
                          className="rounded-md border border-theme px-2 py-2 text-colors-primary outline-theme w-full pr-10"
                          {...register("password", {
                            required: "This field is required",
                          })}
                        />
                        <FaRegEye
                          className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                          size={20}
                          onClick={() => setShowPwd(false)}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          type="password"
                          className="rounded-md border border-theme px-2 py-2 text-colors-primary outline-theme w-full pr-10"
                          {...register("password", {
                            required: "This field is required",
                          })}
                        />
                        <FaRegEyeSlash
                          className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                          size={20}
                          onClick={() => setShowPwd(true)}
                        />
                      </>
                    )}
                  </div>
                  <div
                    className="flex flex-col cursor-pointer ml-2 hover:italic text-[13px] text-blue-900"
                    onClick={() => generateRandomString(15)}
                  >
                    generate password
                  </div>
                </div>
                {errors.password && (
                  <div className="ml-[15%] flex flex-row text-red-500 font-semibold">
                    {errors.password.message}
                  </div>
                )}
              </div>
            </>
          )}
        </form>
           {/* permissions */}
           <div className="flex flex-row items-center">
            <div className="flex flex-col w-[15%]">Permission</div>
            <div className="flex items-center space-x-3">
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={isSuperAdmin}
                  onChange={handleSwitchToggle}
                  className="sr-only peer"
                />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:bg-blue-600 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Super Admin
                </span>
              </label>
            </div>
          </div>

          {/* Permission Lists */}
          {!isSuperAdmin && (
            <div className="flex h-auto space-y-2">
              <div className="mt-2 w-[15%]">Page Lists</div>
              <div className="grid grid-cols-3 gap-2 w-[60%]">
                {permissions?.map((page, index) => (
                  <label key={index} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      value={page}
                      onChange={() => handlePermissionClick(page)}
                      checked={selectedPermission.includes(page)}
                    />
                    <span>{page}</span>
                  </label>
                ))}
              </div>
            </div>
          )}
      </div>

      <div className="flex items-center justify-end space-x-5 mt-5">
        <PrimaryButton onClicking={onClose}>
          <div className="px-4 py-3">Cancel</div>
        </PrimaryButton>
        <PrimaryButton
          onClicking={handleSubmit((data) => {
            onSubmit(data, formData._id);
          })}
        >
          <div className="px-4 py-3">
            {type === "createForm" ? "Create" : "Update"}
          </div>
        </PrimaryButton>
      </div>
    </div>
  );
}

export default UserEditForm;

UserEditForm.propTypes = {
  type: propTypes.string.isRequired,
  id: propTypes.string.isRequired,
  onConfirm: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  formData: propTypes.shape({
    name: propTypes.string,
    title: propTypes.string,
    tandc: propTypes.string,
    benefits: propTypes.string,
    publish: propTypes.bool,
    type: propTypes.string,
    order: propTypes.number,
  }).isRequired,
};

