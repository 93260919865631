import React, { useState, useEffect, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosClient from "../../axios-client";
import CustomizedTable from "../../components/CustomizedTable";
import ActionComponent from "../../components/ActionComponent";
import LoadingComponent from "../../components/Loading";
import clearSearch from "../../components/CustomizedTable/util";
import Header from "../../components/PageHeader";
import CustomAlert from "../../components/Notification/CustomAlert";
import FormModal from "../../components/FormModal";
import UserCreateForm from "../../components/ModalBox/UserCreateForm";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import UserEditForm from "../../components/ModalBox/UserEditForm";

const Register = () => {
  const [data, setData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isShowCreate, setIsShowCreate] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [filterData, setFilterData] = useState("");
  const [searchText, setSearchText] = useState("");

  const [d, setD] = useState([]);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    type: "",
    btnLabel: "",
    btnColor: "",
    onBtnClick: null,
  });

  const defaultData = {
    _id: "",
    username: "",
    fullname: "",
    email: "",
    permissions: [],
  };
  const [formData, setFormData] = useState(defaultData);

  // get all admins
  const fetchData = async ([pageParams, limitParams]) => {
    try {
      const response = await axiosClient.get(
        `/backpanel/get-admin?page=${pageParams}&limit=${limitParams}`
      );

      const d = response?.data;
      setData(d || []);
      setPermissions(d?.PERMISSIONS_LIST_BY_PAGE || []);
    } catch (err) {
      console.log("err:::", err);
    }
  };

  const {
    data: any,
    mutate,
    isLoading,
  } = useSWR([page, limit, filterData], fetchData);
  const { trigger, isMutating } = useSWRMutation(
    [page, limit, filterData],
    fetchData
  );

  const titles =
    data && data.columns
      ? data.columns
          .filter((x) => x.filter !== false)
          .map((col) => col.accessor)
      : [];

  const [multiSelectValue, setMultiSelectValue] = useState(
    titles.reduce((accumulator, value) => ({ ...accumulator, [value]: [] }), {})
  );
  const defaultFilteredTargetValues = titles?.reduce(
    (accumulator, value) => ({ ...accumulator, [value]: ["DEFAULT"] }),
    {}
  );
  const [filteredTargets, setFilteredTargets] = useState(
    defaultFilteredTargetValues
  );

  const handleFilter = () => {
    // let result = "";
    // if (filteredTargets && Object.keys(filteredTargets).length > 0) {
    //   Object.entries(filteredTargets).forEach(([key, value]) => {
    //     result += value[0] === "DEFAULT" ? "" : `&${key}=${value.join(",")}`;
    //   });
    // }
    // setFilterData(result);
    console.log("handle filter");
  };

  const exportExternal = async (format) => {
    console.log("hi");
  };

  const createNewUser = async (body) => {
    try {
      const response = await axiosClient.post(`/backpanel/create-admin`, body);
      await mutate();
      toast(response?.data?.message, { type: "success" });
      return response;
    } catch (err) {
      if (err?.response && err?.response?.status === 409) {
        toast("User already exists", { type: "error" });
      } else {
        toast(err?.response?.data?.message, { type: "error" });
      }
      return null;
    }
  };

  const submitNewUser = async (newData, type) => {
    if (type === "create") {
      const resp = await createNewUser(newData);
      if (resp) {
        setIsShowCreate(false);
      }
    }
  };
  const deleteData = async (row) => {
    const body = {
      _id: row?._id,
    };
    setAlertState({
      ...alertState,
      open: false,
    });
    // const response = await axiosClient.delete(`/backpanel/delete-admin`, body);
    // await trigger({ ...userLists, page, limit, filterData })
    // await mutate();
  };

  const updateUser = async (body) => {
    try {
      const response = await axiosClient.put(`/backpanel/update-admin`, body);
      await mutate();
      setIsOpenEdit(false);
      toast(response?.data?.message, { type: "success" });

      setFormData({
        ...formData,
      });

      setD([]);
      return response;
    } catch (err) {
      setIsOpenEdit(false);
      toast(err?.response?.data?.message, { type: "error" });
      return null;
    }
  };

  const editData = async (row) => {
    const tableData = {
      _id: row._id,
      username: row.username,
      fullname: row.fullname,
      email: row.email,
      permissions: row.permissions,
    };
    setFormData(tableData);
    setIsOpenEdit(true);
  };

  const columns = useMemo(() => {
    if (!data || !data?.columns) return [];

    const columnData = [
      {
        Header: "Action",
        accessor: "action",
        filter: false,
        Cell: ({ row }) =>
          ActionComponent({
            row,
            alertState,
            setAlertState,
            editData,
            deleteData,
          }),
        width: 100,
      },
      ...data?.columns,
    ];
    const changeCol = columnData.map((col) => {
      const column = col;

      if (column.accessor?.includes("permissions")) {
        column.Cell = ({ cell }) =>
          cell?.value && Array.isArray(cell.value)
            ? cell.value.map((tag, index) => (
                <React.Fragment key={index}>
                  {tag}
                  {index !== cell.value.length - 1 ? " , " : ""}
                </React.Fragment>
              ))
            : "";

        column.width = 200;
      }

      return column;
    });
    return changeCol;
  }, [data]);

  const globalColumn = useMemo(() => {
    const global = columns.map((col) => {
      const tempColumn = col;
      tempColumn.show = true;
      return tempColumn;
    });
    return global;
  }, [columns]);

  return (
    <>
      <div className="absolute right-0 top-0 m-5 bg-white">
        <ToastContainer autoClose={3000} />
      </div>
      <Header pageName="Register" />

      <CustomizedTable
        dataRows={data?.data || []}
        dataColumns={columns || []}
        totalItems={data?.count || 0}
        pagination
        page={page}
        setPage={(e) => setPage(e)}
        limit={limit}
        setLimit={(e) => setLimit(e)}
        handleFilter={() => handleFilter()}
        clearSearch={() =>
          clearSearch(
            setFilteredTargets,
            defaultFilteredTargetValues,
            setFilterData,
            multiSelectValue,
            setMultiSelectValue
          )
        }
        multiSelectValue={multiSelectValue}
        setMultiSelectValue={setMultiSelectValue}
        filteredTargets={filteredTargets}
        setFilteredTargets={setFilteredTargets}
        defaultFilteredTargetValues={defaultFilteredTargetValues}
        loading={false}
        onCreate={() => setIsShowCreate((value) => !value)}
        progressComponent={<LoadingComponent themeColor="#000F3C" />}
        refreshRow={() => trigger({ ...data, page, limit, filterData })}
        searchText={searchText}
        setSearchText={setSearchText}
        exportExternal={exportExternal}
        colWithShow={globalColumn}
        pageName="register"
      />

      <FormModal
        header="Create New User"
        isOpen={isShowCreate}
        title="Create New User"
      >
        <UserCreateForm
          type="createForm"
          previewId="previewDialog"
          onCancel={() => setIsShowCreate(false)}
          onSubmitNewUser={(e) => submitNewUser(e, "create")}
        />
      </FormModal>

      <FormModal
        isOpen={isOpenEdit}
        onClose={() => {
          setIsOpenEdit(false);
        }}
        header="Edit User"
        onConfirm={(formdata) => {
          updateUser(formdata);
        }}
      >
        <UserEditForm
          type="editForm"
          id="edit_form"
          formData={formData}
          onClose={() => {
            setFormData({
              ...formData,
            });
            setIsOpenEdit(false);
          }}
          onConfirm={(formData, id) => {
            updateUser(formData, id);
          }}
        />
      </FormModal>

      <CustomAlert
        open={alertState.open}
        type={alertState.type}
        message={alertState.message}
        btnColor={alertState.btnColor}
        btnLabel={alertState.btnLabel}
        onClose={() => setAlertState({ ...alertState, open: false })}
        onBtnClick={alertState.onBtnClick}
      />
    </>
  );
};

export default Register;
