import React, { useEffect } from 'react';
import { AiOutlineClear, AiOutlineSearch } from 'react-icons/ai';
import PropTypes from 'prop-types';
import OrdinaryCard from '../Card/OrdinaryCard';
import SelectBox from '../SelectBox/SelectBox';
import CustomDateRangePicker from '../DatePicker/DateRangePicker/index'
import PrimaryButton from '../Buttons/PrimaryButton';

export function Body({
  // sortOrder,
  // setSortOrder,
  columnStates,
  multiSelectValue,
  setMultiSelectValue,
  filteredTargets,
  setFilteredTargets,
  defaultFilteredTargetValues,
  clearSearch,
  handleFilter,
  loading,
}) {
  const handleMultiSelect = (value, name) => {
    // console.log('handleMultiSelect', value[0]?.value);
    // if (multiSelectValue[name]) {
    if (value.length > 0) {
      setMultiSelectValue({ ...multiSelectValue, ...{ [name]: value } });
    } else {
      // if Unselect all, value is {} empty obj.so need to assign []
      const tempValue = [];
      setMultiSelectValue({ ...multiSelectValue, ...{ [name]: tempValue } });
    }
    if (value.length > 0) {
      const data = value.map((val) => val.value);

      setFilteredTargets({
        ...filteredTargets,
        [name]: data,
      });
    } else {
      setFilteredTargets({
        ...filteredTargets,
        [name]: [defaultFilteredTargetValues[name][0]],
      });
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-4 laptop:grid-cols-6">

        {columnStates
          .filter((col) => col.filter)
          .map((filter) => (
            <>
              {filter.type === 'multiselect' ? (
                <React.Fragment key={filter.accessor}>
                  <div className="body-font dark:text-color-dark_primary p-2 font-semibold">
                    {filter.Header}
                  </div>
                  <div className="laptop:col-span-2">
                    <SelectBox
                      options={filter.options}
                      name={filter.accessor}
                      value={multiSelectValue}
                      onChange={handleMultiSelect}
                      setValue={setMultiSelectValue}
                      allowSelectAll
                      isMulti
                    />
                  </div>
                </React.Fragment>
              ) : (
                filter.type === 'daterange' && (
                  <React.Fragment key={filter.accessor}>
                    <div className="body-font dark:text-color-dark_primary p-2 font-semibold">
                      {filter.Header}
                    </div>
                    <div className="z-0 laptop:col-span-2">
                      <CustomDateRangePicker
                        name={filter.accessor}
                        filteredTargets={filteredTargets}
                        setFilteredTargets={setFilteredTargets}
                      />
                    </div>
                  </React.Fragment>
                )
              )}
            </>
          ))}
      </div>
      <div className="flex items-center justify-end space-x-5 py-3">
        <PrimaryButton onClicking={clearSearch}>
          <div className="flex items-center justify-center px-2 py-2">
            <AiOutlineClear />
            <div className="ml-1"> Clear </div>
          </div>
        </PrimaryButton>
        <PrimaryButton isDisabled={loading} onClicking={handleFilter}>
          <div className="flex items-center justify-center px-2 py-2">
            <AiOutlineSearch />
            <div className="ml-1"> Search </div>
          </div>
        </PrimaryButton>
      </div>
    </>
  );
}

Body.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  columnStates: PropTypes.arrayOf(
    PropTypes.shape({
      Cell: PropTypes.func,
      Header: PropTypes.string,
      accessor: PropTypes.string,
      filter: PropTypes.bool,
      show: PropTypes.bool,
      width: PropTypes.number,
    })
  ).isRequired,
  defaultFilteredTargetValues: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string)
  ).isRequired,
  filteredTargets: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.instanceOf(Date),
      ])
    )
  ).isRequired,
  handleFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  multiSelectValue: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
  ).isRequired,
  setFilteredTargets: PropTypes.func.isRequired,
  setMultiSelectValue: PropTypes.func.isRequired,
};

function FilterComponent({
  sortOrder,
  setSortOrder,
  columnStates,
  defaultFilteredTargetValues,
  filteredTargets,
  setFilteredTargets,
  multiSelectValue,
  setMultiSelectValue,
  clearSearch,
  handleFilter,
  loading,
}) {
  return (
    <OrdinaryCard>
      <Body
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        columnStates={columnStates}
        multiSelectValue={multiSelectValue}
        setMultiSelectValue={setMultiSelectValue}
        filteredTargets={filteredTargets}
        setFilteredTargets={setFilteredTargets}
        defaultFilteredTargetValues={defaultFilteredTargetValues}
        clearSearch={clearSearch}
        handleFilter={handleFilter}
        loading={loading}
      />
    </OrdinaryCard>
  );
}

export default FilterComponent;

FilterComponent.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  columnStates: PropTypes.arrayOf(
    PropTypes.shape({
      Cell: PropTypes.func,
      Header: PropTypes.string,
      accessor: PropTypes.string,
      filter: PropTypes.bool,
      show: PropTypes.bool,
      width: PropTypes.number,
    })
  ).isRequired,
  defaultFilteredTargetValues: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string)
  ).isRequired,
  filteredTargets: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.instanceOf(Date),
      ])
    )
  ).isRequired,
  handleFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  multiSelectValue: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
  ).isRequired,
  setFilteredTargets: PropTypes.func.isRequired,
  setMultiSelectValue: PropTypes.func.isRequired,
};
