import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

function ActivitiesUpdateForm({
  type,
  id,
  onConfirm,
  onClose,
  formData,
  onGetRemoveValues,
}) {
  return (
    <div className="px-5 py-5">
      <div className="w-full space-y-7 rounded-[30px] border border-theme bg-white px-7 py-7 shadow-md">
       Activities Update Form
      </div>
    </div>
  );
}

export default ActivitiesUpdateForm;
