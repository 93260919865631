import React from "react";
import PropTypes from "prop-types";

function Toggle({ id, checked, handleOnChange }) {
  return (
    <label
      htmlFor={id}
      className="inline-flex relative items-center cursor-pointer"
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={() => handleOnChange()}
        id={id}
        className="sr-only peer"
      />
      <div className="w-11 h-6 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-white dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-[#FFFFFF] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-[#FFFFFF] after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
      {/* <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
        {id}
      </span> */}
    </label>
  );
}

export default Toggle;

Toggle.propTypes = {
 id : PropTypes.string.isRequired,
 checked : PropTypes.bool.isRequired,
 handleOnChange : PropTypes.func.isRequired,
};

