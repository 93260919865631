
import React, { useState, useRef, useEffect, FormEvent } from 'react';

import { GiCancel } from 'react-icons/gi';

import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const UploadImg = ({
  label,
  isCoverImg,
  selectImg,
  disabled,
  images,
  imageNames,
  required,
}) => {
  const [selectedFiles, setSelectedFiles] = useState(images);

  const [selectedFileNames, setSelectedFileNames] = useState(imageNames);

  const fileInputRef = useRef(null);

  useEffect(() => {
    setSelectedFiles(images);

    setSelectedFileNames(imageNames);
  }, [images, imageNames]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files || []);

    const newFiles = files.filter(
      (file) =>
        !selectedFileNames.some((existingFile) =>
          existingFile.includes(file?.name)
        )
    );

    const fileNames = newFiles.map((file) => {
      return uuidv4() + '@' + file?.name;
    });

    if (isCoverImg) {
      setSelectedFiles([newFiles[0]]);
      setSelectedFileNames([fileNames[0]]);
      selectImg(files, fileNames);
    } else {
      setSelectedFiles((prev) => [...prev, ...newFiles]);
      setSelectedFileNames((prev) => [...prev, ...fileNames]);
      selectImg(
        [...selectedFiles, ...newFiles],
        [...selectedFileNames, ...fileNames]
      );
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    e.stopPropagation();

    if (disabled) {
      return null;
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();

    e.stopPropagation();

    if (disabled) {
      return null;
    }

    const droppedFiles = Array.from(e.dataTransfer.files || []);

    const droppedFileNames = droppedFiles.map((droppedFile) => {
      const uuid = uuidv4();

      return uuid + '@' + droppedFile.name;
    });

    if (droppedFiles.length > 0) {
      if (isCoverImg) {
        setSelectedFiles([droppedFiles[0]]);

        setSelectedFileNames([droppedFileNames[0]]);

        setIsTextVisible(false);

        selectImg([droppedFiles[0]], droppedFileNames);
      } else {
        setSelectedFiles((prevFiles) => [...prevFiles, ...droppedFiles]);

        setSelectedFileNames((prevFiles) => [
          ...prevFiles,
          ...droppedFileNames,
        ]);

        setIsTextVisible(false);

        selectImg(
          [...selectedFiles, ...droppedFiles],
          [...selectedFileNames, ...droppedFileNames]
        );
      }
    }
  };

  const handleDeleteImage = (index, e) => {
    e.preventDefault();

    const updatedFiles = [...selectedFiles];

    const updatedFileNames = [...selectedFileNames];

    updatedFiles.splice(index, 1);

    updatedFileNames.splice(index, 1);

    if (isCoverImg) {
      setSelectedFiles(updatedFiles[0] ? [updatedFiles[0]] : []);

      setSelectedFileNames(updatedFileNames[0] ? [updatedFileNames[0]] : []);

      selectImg(updatedFiles, updatedFileNames);
    } else {
      setSelectedFiles(updatedFiles);

      setSelectedFileNames(updatedFileNames);

      selectImg(updatedFiles, updatedFileNames);
    }
  };

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  };

  const handleDragEnd = (e) => {
    if (e.destination) {
      const tempData = Array.from(selectedFiles);
      const [sourceData] = tempData.splice(e.source.index, 1);
      tempData.splice(e.destination.index, 0, sourceData);
      const fileNames = tempData.map((file) => {
        if (file?.name.indexOf('@', 36) !== -1) {
          return file?.name;
        } else {
          return uuidv4() + '@' + file?.name;
        }
      });
      setSelectedFiles(tempData);
      setSelectedFileNames(fileNames);
      selectImg(tempData, fileNames);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="flex flex-col space-y-5">
        <p className="font-semibold">
          {label}{' '}
          {required && <span className="font-bold text-red-500">*</span>}
        </p>
        <div
          className="flex flex-col items-center justify-center rounded bg-[#F7F7F7] p-7 shadow"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div
            className={`h-[200px] w-[90%] overflow-hidden overflow-x-auto rounded-lg border-2 border-dashed border-[#000f3c]`}
          >
            <input
              type="file"
              className="hidden"
              onChange={handleFileChange}
              ref={fileInputRef}
              multiple
              accept="image/*"
            />
            <Droppable droppableId="imageDroppable" direction="horizontal">
              {(provider) => (
                <div
                  ref={provider.innerRef}
                  {...provider.droppableProps}
                  className={`${
                    selectedFiles?.length > 1 ? 'w-max p-2' : 'w-full'
                  } flex h-full cursor-pointer items-center justify-center gap-3 object-center`}
                >
                  {selectedFiles?.length === 0 ? (
                    <div className="flex h-full w-full flex-col items-center justify-center">
                      <img
                        src="/images/browseIcon/Browse.svg"
                        alt="browser icon"
                        width={30}
                        height={30}
                      />
                    </div>
                  ) : (
                    selectedFiles?.length > 0 &&
                    selectedFiles?.map((file, index) => (
                      <>
                        <Draggable
                          key={index}
                          draggableId={`DraggableImage${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="relative"
                            >
                              <img
                                src={URL.createObjectURL(file)}
                                // src={file}
                                alt={`Image ${index}`}
                                width={100}
                                height={100}
                                className="h-40 w-48 object-cover object-center"
                              />

                              {!disabled && (
                                <button
                                  className="absolute right-0 top-0 cursor-pointer bg-white text-red-600"
                                  onClick={(e) => handleDeleteImage(index, e)}
                                >
                                  <GiCancel size={12} />
                                </button>
                              )}
                            </div>
                          )}
                        </Draggable>
                      </>
                    ))
                  )}

                  {provider.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <p className="mt-4 text-gray-600">
            Drag your file here or &nbsp;
            <button
              className={`text-[#000f3c]  ${
                disabled
                  ? 'cursor-not-allowed text-blue-300'
                  : 'cursor-pointer '
              }`}
              onClick={handleBrowseClick}
              disabled={disabled}
              type="button"
            >
              browse
            </button>
          </p>
        </div>
      </div>
    </DragDropContext>
  );
};

export default UploadImg;
