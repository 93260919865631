import React from "react";
import ReactDOM from "react-dom/client";

import "../src/styles/globals.css";
import { routes } from "./routes";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>

  <RouterProvider router={routes} />
  // </React.StrictMode>
);
reportWebVitals();
