import React, { useRef } from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import styles from "../ModalBox/style.module.css";

function FormModal({ id, isOpen = false, header, children }) {
  const box = useRef();
  const className = {
    enterActive: styles.FormModalEnterActive,
    enterDone: styles.FormModalEnterDone,
    exitActive: styles.FormModalExitActive,
    exitDone: styles.FormModalExitDone,
  };

  return (
    <CSSTransition
      nodeRef={box}
      classNames={className}
      in={isOpen}
      mountOnEnter
      unmountOnExit
      timeout={300}
    >
      <div
        id={id}
        className="fixed w-full h-full overflow-y-auto top-5 left-0 z-20 bg-black/20 backdrop:blur-sm flex justify-center"
      >
        <div
          ref={box}
          className="relative w-[95%] tablet-sm:w-[65%] laptop:w-[65%] desktop:w-[55%] wide-desktop:w-[50%] h-fit max-h-[90%] overflow-y-auto bg-white shadow-md rounded-lg px-5 py-5 "
        >
          <h2 className="text-2xl h-[10%] font-bold flex justify-center items-center text-colors-primary tracking-wide">
            {" "}
            {header}{" "}
          </h2>
          <div className="h-full overflow-y-auto">{children}</div>
        </div>
      </div>
    </CSSTransition>
  );
}

export default FormModal;

FormModal.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  header: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

FormModal.defaultProps = {
  id: "modal-box",
  isOpen: false,
};
