import React, { useEffect, useRef, useState } from "react";
import propTypes from "prop-types";
import { useForm } from "react-hook-form";
import HookFormInput from "../Inputs/HookFormInput";
import PrimaryButton from "../Buttons/PrimaryButton";
import styles from "./style.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { GiCancel } from "react-icons/gi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TbTable } from "react-icons/tb";

function NewsUpdateForm({
  type,
  id,
  onConfirm,
  onClose,
  formData,
  onGetRemoveValues,
}) {
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [existingImages, setExistingImages] = useState(
    [formData?.imagefile] || []
  );
  const [removeImages, setRemoveImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const fileInputRef = useRef(null);
  const editorRef = useRef();
  // const { CKEditor, ClassicEditor } = editorRef.current || {};

  const [posteddate, setPosteddate] = useState(
    id === "edit_form" ? new Date(formData?.posteddate) : null
  );

  const [title, setTitle] = useState(id === "edit_form" ? formData?.title : "");

  const [titleMM, setTitleMM] = useState(
    id === "edit_form" ? formData?.titlemm : ""
  );

  const [content, setContent] = useState(
    id === "edit_form" ? formData?.content : ""
  );

  const [contentMM, setContentMM] = useState(
    id === "edit_form" ? formData?.contentmm : ""
  );

  const style = {
    label: "text-colors-primary text-base font-semibold",
    inputWrapper: "tablet:w-[45%]",
  };

  const [fromDateErrMsg, setFromDateErrMsg] = useState("");
  const [titleErr, setTitleErr] = useState("");
  const [titleMMErr, setTitleMMErr] = useState("");
  const [contentErr, setContentErr] = useState("");
  const [contentMMErr, setContentMMErr] = useState("");
  const fromDateRef = useRef();
  const titleRef = useRef();
  const titleMMRef = useRef();
  const contentRef = useRef();
  const contentMMRef = useRef();

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: id === "edit_form" ? formData.title : "",
      titlemm: id === "edit_form" ? formData.titlemm : "",
      content: id === "edit_form" ? formData.content : "",
      contentmm: id === "edit_form" ? formData.contentmm : "",
      publish: id === "edit_form" ? formData.publish : false,
      posteddate:
        id === "edit_form"
          ? new Date(formData.posteddate).toLocaleString()
          : new Date(),
      imagefile: id === "edit_form" ? formData.imagefile : [],
    },
    mode: "onChange",
  });

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  useEffect(() => {
    setValue("posteddate", posteddate);
  }, [posteddate, setValue]);

  const handleFromDateChange = (date) => {
    setPosteddate(date);
    if (date) {
      setFromDateErrMsg("");
      setValue("posteddate", date);
    }
  };

  const handleDeleteExistingImage = (imageUrl, index) => {
    // Remove image from existingImages and track it in removeImages
    setExistingImages((prevImages) => {
      const updatedImages = prevImages[0]?.filter((_, i) => i !== index);
      setRemoveImages((prevRemoveImages) => [
        ...prevRemoveImages,
        prevImages[0][index]  // Add the deleted image to removeImages
      ]);
      return [updatedImages];
    });
  };
  

    
    const handleDeleteNewImage = (index) => {
        setNewImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages?.splice(index, 1);
            return updatedImages;
        });
    };
    console.log('remove img::', removeImages)
    console.log('existing img::', existingImages)

  const handleNewImageSelection = (e) => {
    const filesArray = Array.from(e.target.files);
    setNewImages((prevImages) => [...prevImages, ...filesArray]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const filesArray = Array.from(e.dataTransfer.files);
    setNewImages((prevImages) => [...prevImages, ...filesArray]);
  };

  const handleDragEnd = (result) => {
    // console.log("result:::", result);
  };

  const submitHandler = (newImages, id) => {

    const formData = {
      publish: watch("publish") || false,
      title: watch("title") || "",
      titlemm: watch("titlemm") || "",
      content: watch("content") || "",
      contentmm: watch("contentmm") || "",
      imagefile: watch("imagefile") || [],
      posteddate,
    };
    

    onConfirm(formData, newImages, id, removeImages);
  };

  return (
    <div className="px-5 py-5">
      <div className="w-full space-y-7 rounded-[30px] border border-theme bg-white px-7 py-7 shadow-md">
        <div className="justify-around tablet:flex">
          <div className="mt-5 flex justify-around space-x-5 tablet:mt-0">
            <div className="flex items-center space-x-3">
              <div className={styles.labelStyle} htmlFor="publish">
                {" "}
                Publish{" "}
              </div>
              <HookFormInput
                type="checkbox"
                placeholder="publish"
                label="publish"
                validate={{ required: false }}
                register={register}
                errors={errors}
              />
            </div>
          </div>
        </div>

        {/* from date */}
        <div className="flex items-center justify-between">
          <span className="text-gray-500 w-[10%]">From</span>
          <div
            className="w-[40%] h-[40px] border rounded-[5px]"
            ref={fromDateRef}
          >
            <DatePicker
              selected={posteddate}
              placeholderText="Select From Date"
              className="p-2 items-center focus:outline-none"
              onChange={handleFromDateChange}
            />
          </div>

          {!posteddate && fromDateErrMsg ? (
            <p className="text-red-500">{fromDateErrMsg}</p>
          ) : (
            ""
          )}
        </div>
        {/* eng title */}
        <div className="">
          {" "}
          <div className={styles.labelStyle} htmlFor="title">
            {" "}
            <div className="mb-2">English</div>
            Title :{" "}
          </div>
          <div className="">
            {editorLoaded ? (
              <CKEditor
                editor={ClassicEditor}
                id="rich-text-editor"
                data={formData.title}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTitle(data);
                  setTitleErr("");
                  setValue("title", data);
                }}
              />
            ) : (
              <div> Editor Loading </div>
            )}
          </div>
        </div>

        {/* mm title */}
        <div className="">
          {" "}
          <div className={styles.labelStyle} htmlFor="title">
            {" "}
            <div className="mb-2">Myanmar</div>
            Title :{" "}
          </div>
          <div className="">
            {editorLoaded ? (
              <CKEditor
                editor={ClassicEditor}
                id="rich-text-editor"
                data={formData.titleMM}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTitleMM(data);
                  setTitleMMErr("");
                  setValue("titleMM", data);
                }}
              />
            ) : (
              <div> Editor Loading </div>
            )}
          </div>
        </div>

        {/* eng content */}
        <div className="">
          {" "}
          <div className={styles.labelStyle} htmlFor="title">
            {" "}
            <div className="mb-2">English</div>
            Content :{" "}
          </div>
          <div className="">
            {editorLoaded ? (
              <CKEditor
                editor={ClassicEditor}
                id="rich-text-editor"
                data={formData.content}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContent(data);
                  setContentErr("");
                  setValue("content", data);
                }}
              />
            ) : (
              <div> Editor Loading </div>
            )}
          </div>
        </div>

        {/* mm content */}
        <div className="">
          {" "}
          <div className={styles.labelStyle} htmlFor="title">
            {" "}
            <div className="mb-2">Myanmar</div>
            Content :{" "}
          </div>
          <div className="">
            {editorLoaded ? (
              <CKEditor
                editor={ClassicEditor}
                id="rich-text-editor"
                data={formData.contentMM}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContentMM(data);
                  setContentMMErr("");
                  setValue("contentMM", data);
                }}
              />
            ) : (
              <div> Editor Loading </div>
            )}
          </div>
        </div>
        {/* upload image */}
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="flex flex-col space-y-5">
            <p className="font-semibold">upload image</p>
            <div
              className="flex flex-col items-center justify-center rounded bg-[#F7F7F7] p-7 shadow"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <div
                className={`h-[200px] w-[400px] overflow-hidden overflow-x-auto rounded-lg border-2 border-dashed border-[#E1FF3C]`}
              >
                <input
                  type="file"
                  className="hidden"
                  onChange={handleNewImageSelection}
                  ref={fileInputRef}
                  multiple
                  accept="image/*"
                />
                <Droppable droppableId="imageDroppable" direction="horizontal">
                  {(provider) => (
                    <div
                      ref={provider.innerRef}
                      {...provider.droppableProps}
                      className={`w-max p-2 flex h-full cursor-pointer items-center justify-center gap-3 text-center mx-auto object-center`}
                    >
                      {existingImages?.length === 0 &&
                      newImages?.length === 0 ? (
                        <div className="flex h-full w-full flex-col items-center justify-center">
                          <img
                            src="/images/browseIcon/Browse.svg"
                            alt="browser icon"
                            width={30}
                            height={30}
                          />
                        </div>
                      ) : (
                        existingImages?.length > 0 &&
                        existingImages[0]?.map((file, index) => (
                        
                          <>
                            <Draggable
                              key={index}
                              draggableId={`DraggableImage${index}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="relative"
                                >
                                  <img
                                    src={`/bucket/motor${file}`}
                                    alt={`Image ${index}`}
                                    width={100}
                                    height={100}
                                    className="h-40 w-48 object-cover object-center"
                                  />
                                  <button
                                    className="absolute right-0 top-0 cursor-pointer bg-white text-red-600"
                                    onClick={() =>
                                      handleDeleteExistingImage(file, index)
                                    }
                                  >
                                    <GiCancel size={12} />
                                  </button>
                                </div>
                              )}
                            </Draggable>
                          </>
                        ))
                      )}

                      {newImages?.length > 0 &&
                        newImages?.map((file, index) => (
                          <>
                            <Draggable
                              key={index}
                              draggableId={`DraggableImage${index}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="relative"
                                >
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`Image ${index}`}
                                    width={100}
                                    height={100}
                                    className="h-40 w-48 object-cover object-center"
                                  />
                                  <button
                                    className="absolute right-0 top-0 cursor-pointer bg-white text-red-600"
                                    onClick={() => handleDeleteNewImage(index)}
                                  >
                                    <GiCancel size={12} />
                                  </button>
                                </div>
                              )}
                            </Draggable>
                          </>
                        ))}

                      {provider.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>

              <p className="mt-4 text-gray-600">
                Drag your file here or &nbsp;
                <button
                  className={`text-[#E1FF3C] cursor-pointer hover:text-yellow-300`}
                  onClick={handleBrowseClick}
                  type="button"
                >
                  browse
                </button>
              </p>
            </div>
          </div>
        </DragDropContext>

        <div className="flex items-center justify-end space-x-5">
          <PrimaryButton onClicking={onClose}>
            <div className="px-4 py-3">Cancel</div>
          </PrimaryButton>
          <PrimaryButton
            onClicking={() => submitHandler(newImages, formData?._id)}
            isDisabled={existingImages?.length === 0 && newImages.length === 0}
          >
            <div className="px-4 py-3">
              {type === "createForm" ? "Create" : "Update"}
            </div>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default NewsUpdateForm;

NewsUpdateForm.propTypes = {
  type: propTypes.string.isRequired,
  id: propTypes.string.isRequired,
  onConfirm: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  formData: propTypes.shape({
    name: propTypes.string,
    title: propTypes.string,
    tandc: propTypes.string,
    benefits: propTypes.string,
    publish: propTypes.bool,
    type: propTypes.string,
    order: propTypes.number,
  }).isRequired,
};
