import { createBrowserRouter, Navigate } from "react-router-dom";
import DashboardLayout from "./dashboard/layout";

import BannerPage from "./pages/Banner/BannerPage";
import Login from "./pages/auth/Login";
import Brand from "./pages/brand/Brand";
import Register from "./pages/register/Register";
import Activities from "./pages/activities/Activities";
import NewsAndEvents from "./pages/news/News";
import CarrerPage from "./pages/career/Career";
import ContactusPage from "./pages/Contactus/ContactusPage";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "/banner",
        element: <BannerPage />,
      },
      {
        path: "/brand",
        element: <Brand />,
      },
      {
        path: "/activities",
        element: <Activities />,
      },
      {
        path: "/contactus",
        element: <ContactusPage />,
      },
      {
        path: "/news",
        element: <NewsAndEvents />,
      },
      {
        path: "/career",
        element: <CarrerPage />,
      },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/",
        element: <Navigate to="/login" />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
