import React from 'react';

const Header = ({ pageName }) => {
  return (
    <div className=" mb-2  flex flex-col gap-1">
      <div className="custom-tooltip text-2xl  text-gray-600 hover:italic">
        {pageName} Page
        <span className="tooltip-text">You are in {pageName} page</span>
      </div>
      <div className=" h-1 w-full border border-b-2 bg-gray-400" />
    </div>
  );
};

export default Header;