import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { MdDelete } from "react-icons/md";
import PropTypes from "prop-types";
import HookFormInput from "../Inputs/HookFormInput";
import styles from "./style.module.css";
import PrimaryButton from "../Buttons/PrimaryButton";
function BannerCreateForm({
  type,
  bannerData,
  onCancel,
  previewId,
  onSubmitBanner,
}) {
  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      publish: type === "editForm" ? bannerData.publish : false,

      imagefile: type === "editForm" ? bannerData.imagefile : "",
    },
  });

  const style = {
    label: "text-colors-primary text-base font-semibold",
    inputWrapper: "tablet:w-[45%]",
  };

  useEffect(() => {
    if (type === "editForm") {
      const preview = document.getElementById(previewId);
      const area = document.getElementById("previewArea");
      preview.src = `data:image/png;base64,${bannerData.imagefile}`;
      area.style.display = "block";
    }
  }, [type]);

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setValue("image", e.target.files[0]);
      const src = URL.createObjectURL(e.target.files[0]);
      const preview = document.getElementById(previewId);
      const area = document.getElementById("previewArea");
      preview.src = src;
      area.style.display = "block";
    }
  };

  const clearImg = () => {
    setValue("image", null);
    const area = document.getElementById("previewArea");
    area.style.display = "none";
  };

  return (
    <div className="sticky top-0 w-full px-5 py-5">
      <div className="w-full space-y-7 rounded-[30px] border border-theme bg-white px-7 py-7 shadow-md">
        {/* publish */}
        <div className="justify-around tablet:flex">
          <div className="mt-5 flex justify-around space-x-5 tablet:mt-0">
            <div className="flex items-center space-x-3">
              <div className={styles.labelStyle} htmlFor="publish">
                {" "}
                Publish{" "}
              </div>
              <HookFormInput
                type="checkbox"
                placeholder="publish"
                label="publish"
                validate={{ required: false }}
                register={register}
                errors={errors}
              />
            </div>
          </div>
        </div>

        {type === "createForm" && (
          <div className="items-center justify-center space-x-3 tablet:flex tablet:flex-col">
            <label htmlFor="img_input" className={styles.img_label}>
              Upload Banner
              <input
                id="img_input"
                className={styles.img_input}
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </label>
            <p className="text-orange-600">
              Recommended resolution is 1440 x 573
            </p>
          </div>
        )}
        <div style={{ display: "none" }} id="previewArea" className="relative">
          <div className="flex w-full justify-center">
            <img id={previewId} alt="preview Banner" />
            <button
              type="button"
              onClick={() => clearImg()}
              className="absolute bottom-0 right-0 flex h-[40px] w-[40px] cursor-pointer items-center justify-center bg-[rgba(0,0,0,0.5)]"
            >
              <MdDelete size={25} color="#FFFFFF" />
            </button>
          </div>
        </div>
        <div className="flex items-center justify-end space-x-5">
          <PrimaryButton onClicking={onCancel}>
            <div className="px-4 py-3">Cancel</div>
          </PrimaryButton>

          <PrimaryButton
            onClicking={handleSubmit((data) => onSubmitBanner(data))}
          >
            <div className="px-4 py-3">
              {type === "createForm" ? "Create" : "Update"}
            </div>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default BannerCreateForm;

BannerCreateForm.propTypes = {
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  previewId: PropTypes.string.isRequired,
  onSubmitBanner: PropTypes.func.isRequired,
  bannerData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
  ),
};

BannerCreateForm.defaultProps = {
  bannerData: {
    publish: false,
    title: "",
    over_layer: false,
    content: "",
    button_url: "",
  },
};
