import React from "react";
import PropTypes from "prop-types";
import css from "./index.module.css";

function PrimaryButton({
  children,
  isDisabled = false,
  onClicking,
  bgColor,
  page,
}) {
  return (
    <div>
      {isDisabled}
      <button
        style={{
          backgroundColor: isDisabled ? "#666" : "#000f3c",
          borderColor: isDisabled ? "#666" : "#000f3c",
          cursor: isDisabled ? "not-allowed" : "pointer",
        }}
        className={`${css.btn2} relative  ${
          page === "novastructure" || page === "agbstructure"
            ? "w-[130px]"
            : "w-full"
        } overflow-hidden rounded-lg border font-semibold uppercase leading-none tracking-wider 
        ${
          page === "novafaq" || page === "novastructure"
            ? "text-[#e1ff3c]"
            : "text-[#FFFFFF] "
        } shadow-md hover:text-theme`}
        type="button"
        disabled={isDisabled}
        onClick={onClicking}
      >
        {!isDisabled ? (
          <>
            <span className="absolute inset-0 bg-[#FFFFFF]" />
            <span className="absolute inset-0 flex items-center justify-center font-bold">
              {children}
            </span>
          </>
        ) : (
          ""
        )}
        {children}
      </button>
    </div>
  );
}

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  onClicking: PropTypes.func.isRequired,
  bgColor: PropTypes.string,
};

PrimaryButton.defaultProps = {
  isDisabled: false,
  bgColor: "#000F3C",
};

export default PrimaryButton;
