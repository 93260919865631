import React, { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

const ActivitiesCreateForm = ({
  type,
  bannerData,
  onCancel,
  previewId,
  onSubmitEvent,
}) => {
  return (
    <div className="px-5 py-5">
      <div className="w-full space-y-7 rounded-[30px] border border-theme bg-white px-7 py-7 shadow-md">
    Activities Create Form
      </div>
    </div>
  );
};

export default ActivitiesCreateForm;
