import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { MdDelete } from "react-icons/md";
import PropTypes from "prop-types";
import HookFormInput from "../Inputs/HookFormInput";
import styles from "./style.module.css";
import PrimaryButton from "../Buttons/PrimaryButton";
import UploadImg from "../UploadImg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function NewsCreateForm({
  type,
  bannerData,
  onCancel,
  previewId,
  onSubmitNew,
}) {
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      publish: type === "editForm" ? bannerData.publish : false,
      title: type === "editForm" ? bannerData.title : "",
      titleMM: type === "editForm" ? bannerData.titleMM : "",
      content: type === "editForm" ? bannerData.content : "",
      contentMM: type === "editForm" ? bannerData.contentMM : "",
      imagefile: type === "editForm" ? bannerData.imagefile : [],
      fromDate: type === "editForm" ? new Date(bannerData.fromDate) : null,
    },
  });

  const [fromDate, setFromDate] = useState(null);
  const [fromDateErrMsg, setFromDateErrMsg] = useState("");
  const [title, setTitle] = useState("");
  const [titleMM, setTitleMM] = useState("");
  const [content, setContent] = useState("");
  const [contentMM, setContentMM] = useState("");
  const [titleErr, setTitleErr] = useState("");
  const [titleMMErr, setTitleMMErr] = useState("");
  const [contentErr, setContentErr] = useState("");
  const [contentMMErr, setContentMMErr] = useState("");
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [images, setImages] = useState();
  const [imageUrls, setImageUrls] = useState();
  const editorRef = useRef();
  // const { CKEditor, ClassicEditor } = editorRef.current || {};
  const style = {
    label: "text-colors-primary text-base font-semibold",
    inputWrapper: "tablet:w-[45%]",
  };

  useEffect(() => {
    if (type === "editForm") {
      const preview = document.getElementById(previewId);
      const area = document.getElementById("previewArea");
      preview.src = `data:image/png;base64,${bannerData.image64}`;
      area.style.display = "block";
    }
  }, [type]);

  // useEffect(() => {
  //   editorRef.current = {
  //     CKEditor: require("@ckeditor/ckeditor5-react").CKEditor,
  //     ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
  //   };
  //   setEditorLoaded(true);
  // }, []);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  const handleFromDateChange = (date) => {
    setFromDate(date);
    if (date) {
      setFromDateErrMsg("");
      setValue("fromDate", date);
    }
  };

  const onSelectEventImages = (image, url) => {
    setImages(image);
    setImageUrls(url);
    setValue("imagefile", image);
  };

  const submitHandler = () => {
    if (!fromDate) setFromDateErrMsg("This field is required");
    if (!title) setTitleErr("This field is required");
    if (!titleMM) setTitleMMErr("This field is required");
    if (!content) setContentErr("This field is required");
    if (!contentMM) setContentMMErr("This field is required");

    if (!fromDate || !title || !titleMM || !content || !contentMM) {
      return true;
    }

    const formData = {
      publish: watch("publish") || false,
      title: watch("title") || "",
      titleMM: watch("titleMM") || "",
      content: watch("content") || "",
      contentMM: watch("contentMM") || "",
      imagefile: watch("imagefile") || [],
      fromDate,
    };

    onSubmitNew(formData);
  };

  return (
    <div className="px-5 py-5">
      <div className="w-full space-y-7 rounded-[30px] border border-theme bg-white px-7 py-7 shadow-md">
        <div className="justify-around tablet:flex">
          <div className="mt-5 flex justify-around space-x-5 tablet:mt-0">
            <div className="flex items-center space-x-3">
              <div className={styles.labelStyle} htmlFor="publish">
                {" "}
                Publish{" "}
              </div>
              <HookFormInput
                type="checkbox"
                placeholder="publish"
                label="publish"
                validate={{ required: false }}
                register={register}
                errors={errors}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center">
          <span className="w-[10%] text-gray-500">From Date</span>
          <div className="h-[40px] w-[40%] rounded-[5px] border">
            <DatePicker
              selected={fromDate}
              onChange={handleFromDateChange}
              placeholderText="Select From Date"
              className="items-center p-2 focus:outline-none"
            />
          </div>
          {!fromDate && fromDateErrMsg ? (
            <p className="text-red-500">{fromDateErrMsg}</p>
          ) : (
            ""
          )}
        </div>

        {/* eng title */}
        <div className="">
          {" "}
          <div className={styles.labelStyle} htmlFor="title">
            {" "}
            <div className="mb-2">English</div>
            Title :{" "}
          </div>
          <div className="">
            {editorLoaded ? (
              <CKEditor
                editor={ClassicEditor}
                id="rich-text-editor"
                data={bannerData?.title}
                onReady={(editor) => {
                  editor?.editing?.view?.change((writer) => {
                    writer?.setStyle(
                      "height",
                      "200px",
                      editor?.editing?.view?.document?.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  const data = editor?.getData();
                  setTitle(data);
                  setTitleErr("");
                  setValue("title", data);
                }}
              />
            ) : (
              <div> Editor Loading </div>
            )}
          </div>
          {!title && titleErr ? <p className="text-red-500">{titleErr}</p> : ""}
        </div>

        {/* mm title */}
        <div className="">
          {" "}
          <div className={styles.labelStyle} htmlFor="title">
            {" "}
            <div className="mb-2">Myanmar</div>
            Title :{" "}
          </div>
          <div className="">
            {editorLoaded ? (
              <CKEditor
                editor={ClassicEditor}
                id="rich-text-editor"
                data={bannerData?.titleMM}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setTitleMM(data);
                  setTitleMMErr("");
                  setValue("titleMM", data);
                }}
              />
            ) : (
              <div> Editor Loading </div>
            )}
          </div>
          {!titleMM && titleMMErr ? (
            <p className="text-red-500">{titleMMErr}</p>
          ) : (
            ""
          )}
        </div>

        {/* eng content */}
        <div className="">
          {" "}
          <div className={styles.labelStyle} htmlFor="title">
            {" "}
            <div className="mb-2">English</div>
            Content :{" "}
          </div>
          <div className="">
            {editorLoaded ? (
              <CKEditor
                editor={ClassicEditor}
                id="rich-text-editor"
                data={bannerData?.content}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContent(data);
                  setContentErr("");
                  setValue("content", data);
                }}
              />
            ) : (
              <div> Editor Loading </div>
            )}
          </div>
          {!content && contentErr ? (
            <p className="text-red-500">{contentErr}</p>
          ) : (
            ""
          )}
        </div>

        {/* mm content */}
        <div className="">
          {" "}
          <div className={styles.labelStyle} htmlFor="title">
            {" "}
            <div className="mb-2">Myanmar</div>
            Content :{" "}
          </div>
          <div className="">
            {editorLoaded ? (
              <CKEditor
                editor={ClassicEditor}
                id="rich-text-editor"
                data={bannerData?.contentMM}
                onReady={(editor) => {
                  editor.editing.view.change((writer) => {
                    writer.setStyle(
                      "height",
                      "200px",
                      editor.editing.view.document.getRoot()
                    );
                  });
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContentMM(data);
                  setContentMMErr("");
                  setValue("contentMM", data);
                }}
              />
            ) : (
              <div> Editor Loading </div>
            )}
          </div>
          {!contentMM && contentMMErr ? (
            <p className="text-red-500">{contentMMErr}</p>
          ) : (
            ""
          )}
        </div>

        <UploadImg
          label="Upload Photos"
          isCoverImg={false}
          selectImg={(image, url) => onSelectEventImages(image, url)}
          images={images ? images : []}
          imageNames={imageUrls ? imageUrls : []}
          required={true}
        />

        <div className="flex items-center justify-end space-x-5">
          <PrimaryButton onClicking={onCancel}>
            <div className="px-4 py-3">Cancel</div>
          </PrimaryButton>
          <PrimaryButton
            onClicking={submitHandler}
            isDisabled={!images ? true : false}
          >
            <div className="px-4 py-3">
              {type === "createForm" ? "Create" : "Update"}
            </div>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default NewsCreateForm;

NewsCreateForm.propTypes = {
  type: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  previewId: PropTypes.string.isRequired,
  onSubmitNew: PropTypes.func.isRequired,
  bannerData: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
  ),
};

NewsCreateForm.defaultProps = {
  bannerData: {
    publish: false,
    title: "",
    over_layer: false,
    content: "",
    button_url: "",
  },
};
