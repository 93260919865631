import React from 'react';
import { BiEdit } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import PropTypes from 'prop-types';

function ActionComponent({
  row,
  alertState,
  setAlertState,
  editData,
  deleteData,
}) {
  return (
    <div className="flex items-center justify-evenly">
      <BiEdit
        onClick={() => editData(row.original)}
        className="cursor-pointer"
        size={25}
      />
      <MdDelete
        onClick={() =>
          setAlertState({
            ...alertState,
            open: true,
            message: 'Are u sure want to delete?',
            type: 'warn',
            btnColor: 'red',
            btnLabel: 'Delete',
            onBtnClick: () => deleteData(row.original),
          })
        }
        className="cursor-pointer"
        size={25}
        color="red"
      />
    </div>
  );
}

ActionComponent.propTypes = {
  row: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.any])).isRequired,
  alertState: PropTypes.PropTypes.objectOf(
    PropTypes.shape({
      btnColor: PropTypes.string,
      btnLabel: PropTypes.string,
      message: PropTypes.string,
      onBtnClick: PropTypes.func,
      onClick: PropTypes.bool,
      open: PropTypes.bool,
      type: PropTypes.string,
    })
  ).isRequired,
  setAlertState: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  deleteData: PropTypes.func.isRequired,
};

export default ActionComponent;
