import React, { useContext, useEffect, useMemo, useState } from "react";

import { ToastContainer, toast } from "react-toastify";

import useSWR from "swr";

import useSWRMutation from "swr/mutation";

import CustomizePublishCell from "../../../src/components/Table/Components/CustomizePublishCell";

import ActionComponent from "../../../src/components/Table/Components/ActionComponent";

import CustomAlert from "../../../src/components/Notification/CustomAlert";

import LoadingComponent from "../../../src/components/Loading";

import CustomizedTable from "../../../src/components/Table/CustomizedTable";

import FormModal from "../../../src/components/FormModal";

import MainLoading from "../../../src/components/MainLoading";

import ErrContext from "../../providers/ErrorProvider";

import "react-toastify/dist/ReactToastify.css";
import NewsCreateForm from "../../components/Forms/NewsCreateForm";
import NewsUpdateForm from "../../components/Forms/NewsUpdateForm";
import CustomizedImageCell from "../../../src/components/Table/Components/CustomizedImageCell";

import { Body } from "../../../src/components/Table/Components/FilterComponent";
import axiosClient from "../../axios-client";

export default function NewsPage() {
  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);

  const [isOpenCreate, setIsOpenCreate] = useState(false);

  const [isShowCreate, setIsShowCreate] = useState(false);

  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const [filterData, setFilterData] = useState("");

  const [searchText, setSearchText] = useState("");

  const [alertState, setAlertState] = useState({
    open: false,

    message: "",

    type: "",

    btnLabel: "",

    btnColor: "",

    onBtnClick: null,
  });

  const defaultData = {
    _id: "",

    title: "",

    titleMM: "",

    content: "",

    contentMM: "",

    publish: false,
    imageStore: [],
    imagefile: [],
    fromDate: "",
  };

  const [formData, setFormData] = useState(defaultData);
  // const [setIsError, setErrMsg] = useContext(ErrContext);
  const [d, setD] = useState([]);

  // get all news
  const fetchData = async ([pageParams, limitParams, filterDataParams]) => {
    let resp;

    if (filterDataParams === "") {
      resp = await axiosClient
        .get(`/backpanel/news?page=${pageParams}&limit=${limitParams}`)
        .then((response) => response?.data)
        .catch((err) => {
          // setIsError(true);
          // setErrMsg({ code: err.response.status, msg: err.message });
        });
    } else {
      resp = await axiosClient
        .get(
          `/backpanel/news?page=${pageParams}&limit=${limitParams}${filterDataParams}`
        )

        .then((response) => console.log("resp", response))

        .catch((err) => {
          // setIsError(true);
          // setErrMsg({ code: err.response.status, msg: err.message });
        });
    }

    return resp;
  };

  const {
    data: news,
    mutate,
    isLoading,
  } = useSWR([page, limit, filterData], fetchData);

  const { trigger, isMutating } = useSWRMutation(
    [page, limit, filterData],
    fetchData
  );

  const titles = news
    ? news?.columns?.filter((x) => x.filter).map((col) => col.accessor)
    : [];

  const [multiSelectValue, setMultiSelectValue] = useState(
    titles?.reduce(
      (accumulator, value) => ({ ...accumulator, [value]: [] }),
      {}
    )
  );

  const defaultFilteredTargetValues = titles?.reduce(
    (accumulator, value) => ({ ...accumulator, [value]: ["DEFAULT"] }),
    {}
  );

  const [filteredTargets, setFilteredTargets] = useState(
    defaultFilteredTargetValues
  );

  // handleFilter

  useEffect(() => {
    trigger({ ...news, page, limit, filterData });

    mutate();
  }, []);

  //add new

  const createNew = async (body) => {
    const resp = await axiosClient

      .post(`/backpanel/news`, body)

      .then(async (response) => {
        await mutate();

        toast(response.data.message, { type: "success" });

        return response;
      })

      .catch((err) => toast(err.message, { type: "error" }));

    return resp;
  };

  const handleFilter = () => {
    let result = "";

    Object.entries(filteredTargets).forEach(([key, value]) => {
      result += value[0] === "DEFAULT" ? "" : `&${key}=${value.join(",")}`;
    });

    setFilterData(result);
  };

  const submitNew = async (newData, type) => {
    if (type === "create") {
      const formData = new FormData();

      for (let i = 0; i < newData.imagefile.length; i++) {
        formData.append("imagefile", newData.imagefile[i]);
      }

      formData.append("publish", newData.publish);

      formData.append("title", newData.title);

      formData.append("titlemm", newData.titlemm);

      formData.append("content", newData.content);

      formData.append("contentmm", newData.contentmm);

      // formData.append("fromDate", Date.parse(newData.fromDate));

      formData.append("posteddate", Date.now());

      const resp = await createNew(formData);

      if (resp) {
        setIsShowCreate(false);
      }
    }
  };

  const exportExternal = async (format) => {
    const response = await axiosClient
      .get(
        `${process.env.nova_apiurl}/news_export_${format}?page=1${filterData}`,
        {
          responseType: "blob",
        }
      )
      .then((res) => res);

    const url = URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");

    link.href = url;

    link.setAttribute(
      "download",
      `news_${new Date().toDateString()}.${format}`
    );

    document.body.appendChild(link);

    link.click();
  };

  const editData = async (row) => {
    const tableData = {
      _id: row._id,
      title: row.title,
      titlemm: row.titlemm,
      content: row.content,
      contentmm: row.contentmm,
      publish: row.publish,
      image: row.imagefile,
      imagefile: row.imagefile,
      // fromDate: row.fromDate,
      posteddate: row.posteddate,
    };

    setFormData(tableData);
    setIsOpenEdit(true);
  };

  const deleteData = async (row) => {
    setAlertState({
      ...alertState,

      open: false,
    });

    await axiosClient
      .delete(`/backpanel/news/${row?._id}`)

      .then((response) => {
        toast(response.data.message, { type: "success" });
      })

      .catch((error) => {
        toast(error.response.data.message, { type: "error" });
      });
    await trigger({ ...news, page, limit, filterData });
    await mutate();
  };

  const updateNew = async (body, id) => {
    const resp = await axiosClient
      .put(`/backpanel/news/${id}`, body)

      .then(async (response) => {
        await mutate();

        setIsOpenEdit(false);

        toast(response.data.message, { type: "success" });

        return response;
      })

      .catch((err) => {
        setIsOpenEdit(false);

        toast(err.data.message, { type: "error" });
      });

    setFormData({
      ...formData,
    });

    setD([]);

    return resp;
  };

  const columns = useMemo(() => {
    if (!news) return [];

    const columnData = [
      {
        Header: "Action",

        accessor: "action",

        filter: false,

        Cell: ({ row }) =>
          ActionComponent({
            row,
            alertState,
            setAlertState,
            editData,
            deleteData,
          }),

        width: 100,
      },

      ...news?.columns,
    ];

    const changeCol = columnData.map((col) => {
      const column = col;

      if (column.accessor === "publish") {
        column.Cell = ({ cell }) => CustomizePublishCell({ cell });

        column.width = 50;
      }

      if (column.accessor?.includes("imagefile")) {
        column.Cell = ({ cell }) => CustomizedImageCell({ cell });

        column.width = 200;
      }

      if (column.accessor === "fromDate") {
        column.Cell = ({ cell }) =>
          cell?.value ? new Date(cell?.value).toDateString() : cell?.value;
        column.width = 200;
      }

      return column;
    });

    return changeCol;
  }, [news]);

  const globalColumn = useMemo(() => {
    const global = columns.map((col) => {
      const tempColumn = col;

      tempColumn.show = true;

      return tempColumn;
    });

    return global;
  }, [columns]);

  if (isLoading || !news || isMutating) {
    return <MainLoading enter={isLoading || isMutating} bg="#000F3C4D" />;
  }

  const handleValues = (values) => {
    setD((prevValues) => [...prevValues, values]);
  };

  const newsData = news?.data.map((item) => ({
    ...item,
    imagefile: `/bucket/motor${item.imagefile}`,
  }));

  return (
    <>
      <MainLoading bg="#000F3C4D" enter={isMutating} />

      <div className="absolute right-0 top-0 m-5 bg-white">
        <ToastContainer autoClose={3000} />
      </div>
      <CustomizedTable
        dataRows={news?.data}
        // dataRows={newsData}
        dataColumns={columns}
        totalItems={news.count}
        pagination
        page={page}
        setPage={(e) => setPage(e)}
        limit={limit}
        setLimit={(e) => setLimit(e)}
        handleFilter={handleFilter}
        clearSearch={() =>
          clearSearch(
            setFilteredTargets,
            defaultFilteredTargetValues,
            setFilterData,
            multiSelectValue,
            setMultiSelectValue
          )
        }
        multiSelectValue={multiSelectValue}
        setMultiSelectValue={setMultiSelectValue}
        filteredTargets={filteredTargets}
        setFilteredTargets={setFilteredTargets}
        defaultFilteredTargetValues={defaultFilteredTargetValues}
        loading={isLoading}
        onCreate={() => setIsShowCreate((value) => !value)}
        progressComponent={<LoadingComponent themeColor="#000F3C" />}
        refreshRow={() => trigger({ ...news, page, limit, filterData })}
        searchText={searchText}
        setSearchText={setSearchText}
        exportExternal={exportExternal}
        colWithShow={globalColumn}
        create={true}
      />

      <FormModal header="Create News" isOpen={isShowCreate} title="Create New">
        <NewsCreateForm
          type="createForm"
          previewId="previewDialog"
          onCancel={() => setIsShowCreate(false)}
          onSubmitNew={(e) => submitNew(e, "create")}
        />
      </FormModal>

      <FormModal
        isOpen={isOpenEdit}
        onClose={() => {
          setIsOpenEdit(false);
        }}
        header="Edit News"
        onConfirm={(data, newImages, id, removeImages) => {
          const fData = new FormData();
          // for (let i = 0; i < newImages[0].length; i++) {
          //   fData.append("imagefile", newImages[0][i]);
          // }
          if (newImages.length != 0) {
            console.log("new images::", newImages);
            fData.append("imagefile", newImages[0]);
          }

          fData.append("title", data.title);
          fData.append("titlemm", data.titleMM);
          fData.append("content", data.content);
          fData.append("contentmm", data.contentMM);
          fData.append("publish", data.publish);
          fData.append("posteddate", Date.parse(data.posteddate));
          fData.append(
            "removeImage",
            newImages ? JSON.stringify(removeImages) : JSON.stringify(d)
          );

          updateNew(fData, id);
        }}
      >
        <NewsUpdateForm
          type="editForm"
          id="edit_form"
          formData={formData}
          onGetRemoveValues={handleValues}
          onClose={() => {
            setFormData({
              ...formData,
            });

            setIsOpenEdit(false);
          }}
          onConfirm={(data, newImages, id, removeImages) => {
            const fData = new FormData();
            // for (let i = 0; i < newImages[0].length; i++) {
            //   fData.append("imagefile", newImages[0][i]);
            // }
            if (newImages.length != 0) {
              console.log("new images::", newImages);
              fData.append("imagefile", newImages[0]);
            }

            fData.append("title", data.title);
            fData.append("titlemm", data.titleMM);
            fData.append("content", data.content);
            fData.append("contentmm", data.contentMM);
            fData.append("publish", data.publish);
            fData.append("posteddate", Date.parse(data.posteddate));
            fData.append(
              "removeImage",
              newImages ? JSON.stringify(removeImages) : JSON.stringify(d)
            );

            updateNew(fData, id);
          }}
        />
      </FormModal>
      <CustomAlert
        open={alertState.open}
        type={alertState.type}
        message={alertState.message}
        btnColor={alertState.btnColor}
        btnLabel={alertState.btnLabel}
        onClose={() => setAlertState({ ...alertState, open: false })}
        onBtnClick={alertState.onBtnClick}
      />
    </>
  );
}
