/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment';

function ExpandableComponent({ data, openIndex, expandOrder }) {
    const allRowsExpand = () => {
        for (let i = 0; i < data.length; i += 1) {
            const getParent = document.getElementById(`expand${i}`)
            const getElement = getParent.querySelector(`#dataview-${i}`);
            getElement.style.display = 'contents';
        }
    };

    const loadExpandData = useCallback(() => {
        const getParent = document.getElementById(`expand${openIndex}`)
        const getElement = getParent.querySelector(`#dataview-${openIndex}`);
        getElement.style.display = 'contents';
        allRowsExpand();
    }, [openIndex]);

    useEffect(() => {
        loadExpandData();
    }, []);

    return (
        <div className='w-full bg-color-secondary dark:bg-color-dark_secondary py-5 px-5 border'>
            {
                data.length > 0 && data.map((d, dataIndex) => (
                    <div key={dataIndex} style={{ display: 'none' }} id={`dataview-${dataIndex}`}>
                        {
                            expandOrder.length > 0 && expandOrder.map((value, index) => (
                                <div style={{ display: value.show === true ? '' : 'none' }} key={index} className='mt-2 font-semibold flex flex-1 flex-row justify-between border rounded-md border-b-indigo-100 py-2 hover:bg-gray-100 dark:bg-color-dark_primary dark:hover:bg-color-secondary dark:border-theme'>
                                    <div className='w-6/12 flex flex-row justify-center'>
                                        <span className='w-full tablet:w-6/12 text-left text-colors-primary'>
                                            {value.Header !== undefined && value.Header}
                                        </span>
                                    </div>
                                    <div className='w-6/12  flex flex-row justify-center'>
                                        <div className='w-full tablet:w-6/12 text-left text-colors-primary'>
                                            {
                                                value.order === 'publish' ? (
                                                    <div className={`${data[dataIndex][value.order] === true ? 'bg-green-600' : 'bg-red-600'} py-2 px-3 text-colors-secondary rounded-lg text-center w-fit`}>
                                                        {data[dataIndex][value.order] === true ? 'Active' : 'Unpublish'}
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {value.order === "createdAt" ? moment(data[dataIndex][value.order]).format('MMMM Do YYYY, h:mm:ss a') : data[dataIndex][value.order]}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                ))
            }
        </div >
    )
}

export default ExpandableComponent

ExpandableComponent.propTypes = {
    data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number
    ]))).isRequired,
    openIndex: PropTypes.number.isRequired,
    expandOrder: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]))).isRequired,
};
