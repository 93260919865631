import React, { useContext, useEffect, useRef, useState } from "react";
import propTypes from "prop-types";
import { useForm } from "react-hook-form";
import { DatePicker } from "rsuite";

import HookFormInput from "../Inputs/HookFormInput";
import PrimaryButton from "../Buttons/PrimaryButton";

// import { ErrContext } from '../../../providers/ErrorProvider';
import HookFormSelect from "../Inputs/HookFormSelect";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function CareerForm({ id, onConfirm, onClose, formData, previewId }) {
  const [loading, setLoading] = useState(false);
  const [editorLoaded, setEditorLoaded] = useState(false);

  // const [setIsError, setErrMsg] = useContext(ErrContext);

  const styles = {
    label: "text-colors-primary text-base font-semibold",
    inputWrapper: "tablet:w-[45%]",
  };

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      _id: id === "edit_form" ? formData._id : "",

      imagefile: id === "edit_form" ? formData.imagefile : "",
      title: id === "edit_form" ? formData.title : "",
      position: id === "edit_form" ? formData.position : "",
      description: id === "edit_form" ? formData.description : "",
      salary: id === "edit_form" ? formData.salary : "",
      type: id === "edit_form" ? formData.type : "",

      post: id === "edit_form" ? formData.post : 0,

      hrphone: id === "edit_form" ? formData.hrphone : "",
      hremail: id === "edit_form" ? formData.hremail : "",

      isremote: id === "edit_form" ? formData.isremote : false,
      publish: id === "edit_form" ? formData.publish : false,
    },
  });

  useEffect(() => {
    if (id === "edit_form") {
      const aa = document.getElementById("Area");
      aa.style.display = "block";
      const preview = document.getElementById(previewId);
      const area = document.getElementById("previewArea");
      preview.src = `/bucket/motor${formData?.imagefile}`;
      area.style.display = "block";
    }
    if (id === "create_form") {
      const aa = document.getElementById("Area");
      aa.style.display = "block";
    }
  }, [id]);

  const [isUpload, setIsUpload] = useState(false);
  const onUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setValue("imagefile", e.target.files[0]);
      setIsUpload(true);
      const src = URL.createObjectURL(e.target.files[0]);
      const preview = document.getElementById(previewId);
      const area = document.getElementById("previewArea");
      preview.src = src;
      area.style.display = "block";
    }
  };

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  return (
    <div id={id} className="space-y-5">
      <div className="w-full space-y-5 laptop:flex laptop:items-center laptop:justify-evenly laptop:space-y-0">
        <div className={`${styles.inputWrapper}`}>
          <div className={styles.label}> Title * </div>
          <HookFormInput
            label="title"
            placeholder="Job Title"
            validate={{ required: true }}
            register={register}
            errors={errors}
          />
        </div>
        <div className={`${styles.inputWrapper}`}>
          <div className={styles.label}> Position * </div>
          <HookFormInput
            label="position"
            placeholder="Position"
            validate={{ required: true }}
            register={register}
            errors={errors}
          />
        </div>
      </div>

      <div className="w-full space-y-5 laptop:flex laptop:items-center laptop:justify-evenly laptop:space-y-0">
        <div className={styles.inputWrapper}>
          <div className={styles.label}>Job Type (Full-Time/Part-Time) *</div>
          <HookFormSelect
            option={["Full time", "Part time", "Hybrid"]}
            label="type"
            register={register}
            errors={errors}
            validate={{ required: true }}
          />
        </div>
        <div className={styles.inputWrapper}>
          <div className={styles.label}>Posts *</div>
          <HookFormInput
            placeholder="Posts"
            label="post"
            validate={{ required: true }}
            register={register}
            errors={errors}
          />
        </div>
      </div>

      <div className="w-full space-y-5 laptop:flex laptop:items-center laptop:justify-evenly laptop:space-y-0">
        <div className={styles.inputWrapper}>
          <div className={styles.label}>Phone *</div>
          <HookFormInput
            placeholder="Phone"
            label="hrphone"
            validate={{ required: true }}
            register={register}
            errors={errors}
          />
        </div>
        <div className={styles.inputWrapper}>
          <div className={styles.label}>HR Email *</div>
          <HookFormInput
            placeholder="HR Email"
            label="hremail"
            validate={{ required: true }}
            register={register}
            errors={errors}
          />
        </div>
      </div>

      <div className="w-full space-y-5 laptop:flex laptop:items-center laptop:justify-evenly laptop:space-y-0">
        <div className={styles.inputWrapper}>
          <div className={styles.label}>Salary *</div>
          <HookFormInput
            placeholder="Salary"
            label="salary"
            validate={{ required: true }}
            register={register}
            errors={errors}
          />
        </div>
        <div className={styles.inputWrapper}></div>
      </div>

      <div className="w-full space-y-5 laptop:flex laptop:items-center laptop:justify-evenly laptop:space-y-0">
        <div className={`flex items-center space-x-3 ${styles.inputWrapper}`}>
          <div htmlFor="publish-toggle" className={styles.label}>
            {" "}
            Is Remote{" "}
          </div>
          <HookFormInput
            type="checkbox"
            placeholder="Is Remote"
            label="isremote"
            validate={{ required: false }}
            register={register}
            errors={errors}
          />
        </div>
        <div className={`flex items-center space-x-3 ${styles.inputWrapper}`}>
          <div htmlFor="publish-toggle" className={styles.label}>
            {" "}
            Publish{" "}
          </div>
          <HookFormInput
            type="checkbox"
            placeholder="Publish"
            label="publish"
            validate={{ required: false }}
            register={register}
            errors={errors}
          />
        </div>
      </div>

      <div style={{ display: "none" }} id="Area" className="relative">
        <div className="items-center justify-center space-x-3 tablet:flex tablet:flex-col">
          <label htmlFor="img_input" className={styles.label}>
            Upload Career
            <input
              id="img_input"
              type="file"
              accept="image/*"
              onChange={onUpload}
            />
          </label>
          <p className="text-orange-600">
            Recommended resolution is 1440 x 573
          </p>
        </div>
      </div>
      <div style={{ display: "none" }} id="previewArea" className="relative">
        <div className="flex w-full justify-center">
          <img id={previewId} alt="preview Client" />
        </div>
      </div>

      <div className="flex w-full flex-col items-center">
        <div className={`${styles.label} w-[95%]`}>Job Description *</div>
        <div className="w-[95%]">
          {editorLoaded ? (
            <CKEditor
              editor={ClassicEditor}
              id="rich-text-editor"
              data={formData?.description}
              onReady={(editor) => {
                editor?.editing?.view?.change((writer) => {
                  writer?.setStyle(
                    "height",
                    "200px",
                    editor?.editing?.view?.document?.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                const data = editor?.getData();

                setValue("description", data);
              }}
            />
          ) : (
            <div> Editor Loading </div>
          )}
        </div>
      </div>

      <div className="mt-5 flex items-center justify-end space-x-5">
        <PrimaryButton onClicking={onClose}>
          <div className="px-3 py-3">Close</div>
        </PrimaryButton>
        <PrimaryButton
          onClicking={handleSubmit((data) => {
            if (id === "edit_form" && !isUpload) {
              delete data.imagefile;
            }

            onConfirm(data);
          })}
        >
          <div className="px-4 py-3">
            {id === "create_form" ? "Create" : "Update"}
          </div>
        </PrimaryButton>
      </div>
    </div>
  );
}

export default CareerForm;

CareerForm.propTypes = {
  id: propTypes.string.isRequired,
  onConfirm: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  formData: propTypes.shape({
    _id: propTypes.string,
    imagefile: propTypes.string,
    title: propTypes.string,
    position: propTypes.string,
    description: propTypes.string,

    type: propTypes.string,
    isremote: propTypes.bool,
    post: propTypes.number,
    salary: propTypes.string,

    hremail: propTypes.string,

    hrphone: propTypes.string,

    publish: propTypes.bool,
  }).isRequired,
};
