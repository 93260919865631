/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

export function ValueContainer({ children, ...props }) {
  let values = children[0];
  const input = children[1];
  if (Array.isArray(values)) {
    const val = (i) => values[i].props.children;
    const { length } = values;
    switch (length) {
      case 1:
        values = `${val(0)}`;
        break;
      case 2:
        values = `${val(0)}, ${val(1)}`;
        break;
      case 3:
        values = `${val(0)}, ${val(1)}, ${val(2)}`;
        break;
      default:
        values = `${length} items selected`;
        break;
    }
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
}

function SelectBox({
  allOption,
  allowSelectAll,
  isMulti,
  name,
  onChange,
  options,
  value,
}) {
  const [allOptionLabel, setAllOptionLabel] = useState('Select All');

  const customStyles = useMemo(
    () => ({
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#ebecf099' : '',
        color: '#fff',
      }),
      valueContainer: (provided) => ({
        ...provided,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        flexWrap: 'nowrap',
        color: '',
      }),
      control: (provided) => ({
        ...provided,
        backgroundColor: 'transparent',
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: '#C0C0C0',
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        borderLeft: '1px solid #C0C0C0',
      }),
      menuList: (provided) => ({
        ...provided,
        backgroundColor: '#000F3C',
      }),
    }),
    []
  );

  if (allowSelectAll) {
    return (
      <Select
        // className="text-colors-secondary"
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isMulti
        value={value[name]}
        components={{ ValueContainer }}
        options={[{ label: allOptionLabel, value: '*' }, ...options]}
        styles={customStyles}
        onChange={(selected) => {
          const condi =
            selected.length !== options.length
              ? setAllOptionLabel('Select All')
              : setAllOptionLabel('Unselect All');
         
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === allOption.value &&
            selected.length - 1 !== options.length
          ) {
            setAllOptionLabel('Unselect All');
            if (Object.keys(value).length >= 0) {
              if (value[name]) {
                value[name] = options;
              } else {
                value[name] = [];
                value[name].push(options);
              }
            }
            return onChange(options, name);
          }
          if (selected !== null && selected.length - 1 === options.length) {
            setAllOptionLabel('Select All');
            if (Object.keys(value).length >= 0) {
              if (value[name]) {
                value[name] = null;
              } else {
                value[name] = [];
                value[name].push(options);
              }
            }
            return onChange({}, name);
          }
          return onChange(selected, name);
        }}
      />
    );
  }

  return (
    <Select
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isMulti={isMulti}
      value={value[name]}
      options={options}
      onChange={onChange}
      components={{ ValueContainer }}
      styles={customStyles}
    />
  );
}

SelectBox.defaultProps = {
  allOption: {
    label: 'Select all',
    value: '*',
  },
};

SelectBox.propTypes = {
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  allowSelectAll: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    })
  ).isRequired,
  value: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array])).isRequired,
};

export default SelectBox;
