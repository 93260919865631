import React from "react";

const CustomizedImageCell = ({ cell }) => {
  const imageStyle =
    "inline-flex justify-center w-[150px] h-[5rem] object-center object-cover";

  return (
    <div className="border border-theme max-w-[150px] inline-flex justify-center rounded overflow-hidden">
      {Array.isArray(cell?.value) ? (
        <div className="w-150 overflow-hidden overflow-x-auto flex items-center gap-0.5 no-scrollbar">
          {cell?.value?.map((img, i) => (
            <>
              <img
                key={i}
                loader={() => `/bucket/motor${img}`}
                // src={`${img}`}
                src={`/bucket/motor${img}`}
                alt="New Image"
                width={400}
                height={200}
                priority
                className={imageStyle}
              />
            </>
          ))}
        </div>
      ) : (
        <>
          <img
            loader={() => `/bucket/motor${cell?.value}`}
            src={`/bucket/motor${cell?.value}`}
            alt="New Image"
            className={imageStyle}
            width={400}
            height={200}
          />
        </>
      )}
    </div>
  );
};

export default CustomizedImageCell;
